import React, { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

import DashboardContext from '../context';
import Overview from './Overview';
import Stats from './Stats';
import RouteWidget from './RouteWidget';
import Alert from './Alert';
import ImageLinks from 'resources/ImageLinks';
import Text from 'modules/Text';
import DateTimeRangePicker from './DateTimeRangePicker';

const useStyles = makeStyles((theme) => ({
	wrapper: { marginLeft: 20, width: 300, position: 'absolute', zIndex: 401, top: 20, height: '70vh' },
	date_title: { fontSize: 16, color: theme.colors.primary },
	time_title: { fontSize: 14, color: theme.colors.gray },
}));

const LeftWidgets = () => {
	const { selected_widget, show_date_range_modal, set_show_date_range_modal, start_date_time, end_date_time } = useContext(DashboardContext);
	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			<div className='flex_between_center' style={{ marginBottom: 18 }}>
				<div className='d-flex cursor-pointer' style={{ justifyContent: 'center', flex: 1 }} onClick={() => set_show_date_range_modal(true)}>
					<div>
						<Text bold className={classes.date_title}>
							{moment(start_date_time).format(`DD MMM 'YY`)}
						</Text>
						<Text semi className={classes.time_title}>
							({moment(start_date_time).format('hh:mm A')})
						</Text>
					</div>
					<Text bold className={classes.date_title} style={{ marginLeft: 5, marginRight: 5 }}>
						-
					</Text>
					<div>
						<Text bold className={classes.date_title}>
							{moment(end_date_time).format(`DD MMM 'YY`)}
						</Text>
						<Text semi className={classes.time_title}>
							({moment(end_date_time).format('hh:mm A')})
						</Text>
					</div>
					<img src={ImageLinks.calendar_filled_dark_purple} alt='left' width={20} height={20} style={{ marginTop: 2, marginLeft: 6 }} />
				</div>
			</div>
			{show_date_range_modal && <DateTimeRangePicker close={() => set_show_date_range_modal(false)} />}

			{_.includes(selected_widget, 'overview') && <Overview />}
			{_.includes(selected_widget, 'stats') && <Stats />}
			{_.includes(selected_widget, 'route') && <RouteWidget />}
			{_.includes(selected_widget, 'alerts') && <Alert />}
		</div>
	);
};

export default LeftWidgets;
