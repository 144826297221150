import _ from 'lodash';
import React from 'react';
import { LinearProgress, makeStyles, Modal } from '@material-ui/core';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'resources/ImageLinks';
import OutlinedButton from './OutlinedButton';
import ContainedButton from './ContainedButton';

const useStyles = makeStyles(() => ({
	modalContainer: { display: 'flex', outline: 'none' },
	componentContainer: {
		padding: 24,
		paddingBottom: 0,
		background: 'white',
		width: 600,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	headerTitle: { fontSize: 18, width: '100%', paddingTop: 5 },
	textContainer: { fontSize: 14, marginTop: 8 },
	containedButtonsContainer: { marginTop: 32, marginBottom: 24, display: 'flex', alignSelf: 'flex-end' },
	button: { borderRadius: 30, height: 46, minWidth: `100px !important` },
}));

const ConfirmClose = ({
	close,
	on_confirm,
	title = 'Are you sure you want to close',
	style = {},
	titleStyles = {},
	headerTitle = '',
	negative_btn_text = 'Cancel',
	positive_btn_text = 'Confirm',
	is_error_msg = false,
	is_save_change = false,
	close_on_confirm = true,
	modal_icon = null,
	count = 0,
	async_length = 1,
	show_negative_btn,
	is_async_done = false,
	handle_negative_action,
}) => {
	const handle_confirm = () => {
		on_confirm();
		if (close_on_confirm) close();
	};
	const classes = useStyles();
	return (
		<Modal open={true} onClose={close} className={classes.modalContainer}>
			<div className={classes.componentContainer} style={style}>
				<div style={{ display: 'flex' }}>
					<img
						src={
							!_.isEmpty(modal_icon)
								? modal_icon
								: is_error_msg
								? ImageLinks.delete_red_ripple
								: is_save_change
								? ImageLinks.green_tick_ripple
								: ImageLinks.excalamation_yellow_ripple
						}
						style={{ width: 48, height: 48 }}
					/>
					<div style={{ marginLeft: 16, width: '100%' }}>
						<div style={{ display: 'flex' }}>
							{headerTitle && (
								<Text semi className={classes.headerTitle}>
									{headerTitle}
								</Text>
							)}
							<div style={{ cursor: 'pointer' }} onClick={close}>
								<img src={ImageLinks.cross_black} width={20} height={20} style={{ paddingTop: 0 }} />
							</div>
						</div>
						<Text medium className={classes.textContainer} style={titleStyles}>
							{title}
						</Text>
					</div>
				</div>

				{!is_async_done && count === 0 && (
					<div className={classes.containedButtonsContainer}>
						{show_negative_btn && (
							<OutlinedButton
								onClick={handle_negative_action ? handle_negative_action : close}
								className={classes.button}
								style={{ marginRight: 12 }}>
								<Text semi style={{ color: theme.colors.primary, fontSize: 18 }}>
									{negative_btn_text}
								</Text>
							</OutlinedButton>
						)}
						<ContainedButton
							className={classes.button}
							style={{ backgroundColor: is_error_msg ? theme.colors.red : theme.colors.primary }}
							onClick={handle_confirm}>
							<Text semi style={{ color: theme.colors.white, fontSize: 18 }}>
								{positive_btn_text}
							</Text>
						</ContainedButton>
					</div>
				)}
				{!is_async_done && count !== 0 && (
					<div className='d-flex justify-content-center align-items-center' style={{ padding: '32px 0', flexDirection: 'column' }}>
						<Text bold style={{ marginBottom: 20, color: theme.colors.darkGrey2 }}>
							<Text bold component={'span'} style={{ color: theme.colors.primary }}>
								{count} of {async_length}
							</Text>{' '}
							processed
						</Text>
						<LinearProgress variant='determinate' style={{ width: '60%' }} color='primary' value={(count / async_length) * 100} />
					</div>
				)}
				{is_async_done && (
					<div className={classes.containedButtonsContainer}>
						<ContainedButton
							className={classes.button}
							style={{ backgroundColor: is_error_msg ? theme.colors.red : theme.colors.primary }}
							onClick={close}>
							<Text semi style={{ color: theme.colors.white, fontSize: 18 }}>
								{positive_btn_text}
							</Text>
						</ContainedButton>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ConfirmClose;
