import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import Text from 'modules/Text';
import React from 'react';
import ImageLinks from 'resources/ImageLinks';
import theme from 'resources/theme';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		right: 20,
		top: 20,
		zIndex: 1000,
		width: 330,
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2.07353px 4.14706px 0px rgba(196, 196, 196, 0.30)`,
	},
	route_item_wrap: {
		width: 275,
		padding: 8,
		borderRadius: 4,
		backgroundColor: theme.colors.white,
		marginBottom: 8,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
		marginLeft: 4,
	},
	header_strip: {
		height: 30,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		borderBottom: `1px solid ${theme.colors.primary}`,
		backgroundColor: theme.colors.light_purple,
		paddingLeft: 12,
		paddingRight: 12,
	},
}));

const OrderCard = ({ data, handle_close }) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<div className={classes.header_strip}>
				<Text bold style={{ fontSize: 12, color: theme.colors.primary }}>
					{data.ref_id ? _.get(data, 'ref_id', '') : data.trip_type === 4 ? _.get(data, 'trip_id', '') : _.get(data, 'mcp_id', '')}
				</Text>
				<img className='cursor-pointer' src={ImageLinks.cross_circle_light_purple} onClick={handle_close} />
			</div>
			<div style={{ padding: 12 }}>
				<div className='d-flex align_item_center'>
					<img src={ImageLinks.chevron_down_hollow_purple} />
					<Text semi style={{ fontSize: 14, marginLeft: 8 }}>
						{data.poc
							? _.get(data, 'poc.name', '')
							: data.task_type == 10
							? _.get(data, 'sender_details.name', '')
							: _.get(data, 'receiver_name', '')}
					</Text>
				</div>
				<Text semi style={{ fontSize: 12, marginLeft: 25, color: theme.colors.gray, amrginTop: 4 }}>
					{data.poc ? _.get(data, 'poc.mobile', '') : data.task_type == 10 ? _.get(data, 'sender_details.mobile', '') : ''}
				</Text>
			</div>
			{data.geofence && (
				<div style={{ padding: 12 }}>
					<div className='d-flex align_item_center'>
						<img src={ImageLinks.dotted_double_circle_black} />
						<Text semi style={{ fontSize: 14, marginLeft: 8 }}>
							{_.get(data, 'geofence', '')}m
						</Text>
					</div>
				</div>
			)}
		</div>
	);
};

export default OrderCard;
