import Text from 'modules/Text';
import _ from 'lodash';
import React from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import theme from 'resources/theme';
import { useState } from 'react';
import ImageLinks from 'resources/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import clsx from 'clsx';
import IconWrapper from 'modules/IconWrapper';

const useStyles = makeStyles(() => ({
	container: {
		padding: 20,
		background: 'white',
		width: 580,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	header_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	header_text: { fontSize: 20 },
	cta_btn: {
		width: 130,
		height: 41,
		borderRadius: 30,
		margin: '0 auto',
		marginTop: 30,
	},
	item_wrapper: {
		padding: 12,
		backgroundColor: `rgba(255, 255, 255, 0.60)`,
		border: `1px solid ${theme.colors.white}`,
		borderRadius: 6,
		boxShadow: `0px 0.75px 2.25px 0px rgba(13, 10, 44, 0.08)`,
		width: 139,
		height: 56,
		marginLeft: 6,
	},
}));

const overview_options = [
	{ key: 'distance_travelled', title: 'Dist. Travelled', icon: ImageLinks.gps_gray, is_disabled: true },
	{ key: 'distance_remaining', title: 'Dist. Remaining', icon: ImageLinks.box_gray, is_disabled: true },
	{ key: 'fuel', title: 'Fuel Usage', icon: ImageLinks.droplets_gray, is_disabled: false },
	{ key: 'speed', title: 'Avg. Speed', icon: ImageLinks.speedometer_gray, is_disabled: false },
	{ key: 'battery', title: 'Battery', icon: ImageLinks.battery_gray, is_disabled: false },
	{ key: 'temperature', title: 'Temperature', icon: ImageLinks.thermometer_gray, is_disabled: false },
];

const SelectOverviewWidgetModal = ({ close }) => {
	const [selected_key, set_selected_key] = useState([]);

	const classes = useStyles();

	const handle_item_press = (key) => {
		const _selected_key = _.cloneDeep(selected_key);
		const index = _.indexOf(_selected_key, key);
		if (index >= 0) {
			_selected_key.splice(index, 1);
		} else {
			_selected_key.push(key);
		}
		set_selected_key(_selected_key);
	};

	return (
		<Modal open={true} onClose={close} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.container}>
				<div className={classes.header_wrap}>
					<Text bold className={classes.header_text}>
						Select Widget
					</Text>
					<img className='cursor-pointer' src={ImageLinks.crossBlack} onClick={close} alt='close' />
				</div>

				<div className='d-flex' style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
					{_.map(overview_options, (overview_item, overview_index) => (
						<div className='d-flex align_item_center' style={{ marginBottom: 4 }}>
							<div onClick={() => handle_item_press(overview_item.key)}>
								<img
									src={
										overview_item.is_disabled
											? ImageLinks.disabled_checkbox
											: _.includes(selected_key, overview_item.key)
											? ImageLinks.filled_checkbox
											: ImageLinks.empty_checkbox
									}
									alt='checkbox'
								/>
							</div>
							<div key={`overviw_item_${overview_index}`} className={clsx('d-flex cursor-pointer align_items_center', classes.item_wrapper)}>
								<div className='d-flex align_item_center' style={{}}>
									<IconWrapper icon={overview_item.icon} />
									<div style={{ marginLeft: 4 }}>
										<Text semi style={{ fontSize: 14 }}>
											120L
										</Text>
										<Text semi style={{ fontSize: 10, color: theme.colors.gray }}>
											{overview_item.title}
										</Text>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>

				<ContainedButton disabled={false} className={classes.cta_btn} onClick={() => {}}>
					<Text semi style={{ fontSize: 16, ...(true && { color: theme.colors.darkGrey2 }) }}>
						Create Route
					</Text>
				</ContainedButton>
			</div>
		</Modal>
	);
};

export default SelectOverviewWidgetModal;
