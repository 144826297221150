import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import constant from './constant';

const cookies = new Cookies();

const url_variants = (base_url = constant.BASE_URL.DEFAULT) => {
	let API_URL;
	API_URL = constant[base_url]
	// switch (constant.ENVIRONMENT) {
	// 	case constant.env.STAGING:
	// 		API_URL = constant[base_url].DEV;
	// 		break;

	// 	case constant.env.PRODUCTION:
	// 		API_URL = constant[base_url].PROD;
	// 		break;

	// 	default:
	// 		break;
	// }

	return API_URL;
};

let utils = {
	request: async ({ version = 1, token, headers = {}, base_url = constant.BASE_URL.DEFAULT, show_error = true, ...rest }) => {
		const config = rest;
		try {
			const API_URL = url_variants(base_url);
			const res = await axios.request({
				baseURL: API_URL + `v${version}.0/`,
				timeout: 3600 * 1000,
				headers: {
					...(token && {
						Authorization: 'Bearer ' + token,
					}),
					...headers,
				},
				...config,
			});

			return Promise.resolve(res.data);
		} catch (error) {
			if (show_error) {
				toast.error(_.get(error, 'response.data.message') || _.get(error, 'response.data.error.message') || '', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
			}
			if (error.response.data.statusCode === 401) {
				window.open('/', '_self');
			}
			return Promise.reject(error);
		}
	},

	setCustomHeader: (headers) => {
		axios.defaults.headers.common = {
			...axios.defaults.headers.common,
			...headers,
		};
	},
	_storeData: (name, value) => {
		cookies.set(name, value, { path: '/' });
	},
	_retrieveData: (name) => {
		return cookies.get(name);
	},
	_removeData: (name) => {
		return cookies.remove(name, { path: '/' });
	},
	setRequestAuthHeader: (accessToken) => {
		utils.setCustomHeader({
			Authorization: 'Bearer ' + accessToken,
			// 'type-of-owner': type.id,
		});

		// utils._storeData(constant.TYPE_OF_OWNER, type);
		utils._storeData(constant.AUTH_TOKEN, accessToken);
	},
	setRequestAuthHeaderBusinessId: (businessId) => {
		utils.setCustomHeader({
			'owner-id': businessId,
		});

		utils._storeData(constant.OWNER_ID, businessId);
	},
	setRequestOwnerId: (type) => {
		utils.setCustomHeader({
			'type-of-owner': type,
		});

		utils._storeData(constant.TYPE_OF_OWNER, type);
	},

	convert_hex_to_rgba: (hexCode, opacity) => {
		let hex = hexCode.replace('#', '');

		if (hex.length === 3) {
			hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
		}

		const r = parseInt(hex.substring(0, 2), 16);
		const g = parseInt(hex.substring(2, 4), 16);
		const b = parseInt(hex.substring(4, 6), 16);

		return `rgba(${r},${g},${b},${opacity / 100})`;
	},
};

export default utils;
