import { HIDE_LOADER, LOGIN_DETAILS_RECEIVED, SHOW_LOADER, UPDATE_SELECTED_WIDGET, URL_BREAK } from './actionConstants';

export const login_details_received = (data) => ({
	type: LOGIN_DETAILS_RECEIVED,
	data,
});

export const update_selected_widget = (data) => ({
	type: UPDATE_SELECTED_WIDGET,
	data,
});

export const url_break = (data) => ({
	type: URL_BREAK,
	data,
});

export const show_loader = () => ({
	type: SHOW_LOADER,
});

export const hide_loader = () => ({
	type: HIDE_LOADER,
});
