import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

import { update_selected_widget } from 'actions/app';
import Text from 'modules/Text';
import ImageLinks from 'resources/ImageLinks';
import theme from 'resources/theme';

const widgets_options = [
	{
		key: 'overview',
		icon: ImageLinks.truck_purple,
	},
	{
		key: 'stats',
		icon: ImageLinks.bar_purple,
	},
	{
		key: 'route',
		icon: ImageLinks.route_purple,
	},
	{
		key: 'alerts',
		icon: ImageLinks.info_circle_purple,
	},
	{
		key: 'share',
		icon: ImageLinks.share_purple,
	},
];

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: 20,
		paddingBottom: 20,
		flexDirection: 'column',
		flex: 1,
	},
	widget_item_wrap: {
		width: 50,
		height: 50,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		boxShadow: `0px 2.77778px 5.55556px 0px rgba(196, 196, 196, 0.30)`,
		marginBottom: 16,
		'&:hover': {
			backgroundColor: `${theme.colors.light_purple} !important`,
			border: '0px !important',
		},
	},
}));

const WidgetSidebar = () => {
	const [white_labeling, set_white_labeling] = useState({});
	const { app } = useSelector((state) => state, shallowEqual);
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();

	const query_object = qs.parse(history.location.search.slice(1));

	useEffect(() => {
		const biz_config = app.app_configs.business_configs[app.owner_id];
		const parent_config_whitelabel = _.get(app.app_configs, `parent_configs.${biz_config?.parent_id}.whitelabeling`, {});
		if (!_.isEmpty(biz_config?.whitelabeling) || !_.isEmpty(parent_config_whitelabel)) {
			set_white_labeling(_.isEmpty(biz_config?.whitelabeling?.brand_logo) ? parent_config_whitelabel : biz_config?.whitelabeling);
		}
	}, []);

	const update_widget = (key) => {
		dispatch(update_selected_widget(key));
	};

	return (
		<div className={clsx(classes.container, 'flex_between_center')}>
			<div>
				<img width={80} height={33} src={!_.isEmpty(white_labeling) ? white_labeling?.brand_logo : ImageLinks.pidge_logo} />
				<div className='flex_center_content cursor-pointer' style={{ flexDirection: 'column', marginTop: 16 }}>
					{_.map(widgets_options, (widget_item, widget_index) => (
						<div key={`widget_item_${widget_index}`} style={{ position: 'relative' }}>
							<div
								onClick={() => update_widget(widget_item.key)}
								className={classes.widget_item_wrap}
								style={{
									border: `1.389px solid ${
										_.includes(app.selected_widget, widget_item.key) ? theme.colors.primary : theme.colors.light_white
									}`,
									backgroundColor: `${_.includes(app.selected_widget, widget_item.key) ? theme.colors.light_purple : theme.colors.white}`,
								}}>
								<img src={widget_index == 0 && query_object.entity_type == 'rider' ? ImageLinks.rider_purple : widget_item.icon} />
							</div>
						</div>
					))}
				</div>
			</div>

			<div className='flex_center_content' style={{ flexDirection: 'column' }}>
				<Text medium style={{ fontSize: 12 }}>
					Powered by
				</Text>
				<img src={ImageLinks.pidge_logo} />
			</div>
		</div>
	);
};

export default WidgetSidebar;
