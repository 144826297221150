import { makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import DashboardContext from '../context';
import _ from 'lodash';
import moment from 'moment';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'resources/ImageLinks';
import OrderCard from './OrderCard';
import clsx from 'clsx';
import constant from 'resources/constant';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		right: 20,
		top: 20,
		zIndex: 1000,
		width: 330,
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		padding: 16,
		maxHeight: 700,
		overflowY: 'auto',
	},
	route_item_wrap: {
		padding: 8,
		borderRadius: 4,
		backgroundColor: theme.colors.white,
		marginBottom: 8,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
	},
	number_wrap: {
		width: 16,
		height: 16,
		borderRadius: 8,
		backgroundColor: theme.colors.light_purple,
		marginRight: 4,
	},
	task_header: {
		display: 'flex',
		padding: '8px 14px',
		alignItems: 'center',
		borderRadius: '8px',
		background: theme.colors.light_purple,
		gap: 35,
	},
	task_sub_text: {
		fontSize: 10,
		color: theme.colors.lightGrey6,
	},
}));

const today_date = moment().format('YYYY-MM-DD');

const ActiveTaskList = () => {
	const { set_center_position, set_zoom, set_selected_route_order_item, rider_active_task, selected_widget, app, selected_route_order_item } =
		useContext(DashboardContext);
	const classes = useStyles();
	if (_.isEmpty(rider_active_task.routes) || !_.includes(selected_widget, 'route') || app.entity_type != 'rider') return null;
	if (!_.isEmpty(selected_route_order_item))
		return (
			<OrderCard
				data={selected_route_order_item}
				handle_close={() => {
					set_zoom(10);
					set_selected_route_order_item(null);
				}}
			/>
		);
	const drop_tasks = rider_active_task?.routes?.filter((item) => item.task_type === constant.TASK_TYPES.DROP.id && !_.isEmpty(item.expected_time));
	const task_count = rider_active_task?.routes?.filter((item) => !_.isEmpty(item.expected_time));

	return (
		<div className={classes.container}>
			<div className={classes.task_header}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Text semi style={{ fontSize: 14, color: theme.colors.darkGrey7 }}>
						{task_count?.length}
					</Text>
					<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey8 }}>
						Tasks
					</Text>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Text semi style={{ fontSize: 14, color: theme.colors.darkGrey7 }}>
						{!_.isEmpty(drop_tasks) ? moment(drop_tasks[drop_tasks.length - 1].expected_time).format('D MMM `YY hh:mm a') : '-'}
					</Text>
					<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey8 }}>
						Completion Time
					</Text>
				</div>
			</div>
			<div className='flex_between_center' style={{ marginTop: 16 }}>
				<Text semi style={{ fontSize: 14 }}>
					Task Sequence
				</Text>
			</div>
			{_.map(rider_active_task.routes, (item, index) => {
				return (
					<div key={`route_detail_item_${index}`} className='d-flex align_item_center'>
						<div className={clsx(classes.number_wrap, 'flex_center_content')}>
							{_.isEmpty(item.expected_time) ? (
								<img src={ImageLinks.tick_circle_solid_gray} width={16} height={16} style={{ marginRight: 4 }} />
							) : (
								<Text bold style={{ fontSize: 12, color: theme.colors.primary }}>
									{index + 1}
								</Text>
							)}
						</div>
						<div
							className={classes.route_item_wrap}
							style={{ width: 300 }}
							onClick={() => {
								set_center_position([item.task_location.latitude, item.task_location.longitude]);
								set_zoom(18);
								set_selected_route_order_item(item);
							}}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Text semi style={{ fontSize: 12, color: theme.colors.primary, textDecorationLine: 'underline' }}>
									{item.trip_type === 4 ? item.trip_id : item.mcp_id}
								</Text>
							</div>

							<div>
								<div style={{ display: 'flex' }}>
									<Text semi style={{ fontSize: 12 }}>
										{!_.isNull(item.expected_time) && !_.isEmpty(item.expected_time)
											? moment(item.expected_time).format('hh:mm A').split(' ')[0]
											: '-'}
									</Text>
									<Text semi style={{ fontSize: 10, alignSelf: 'center' }}>
										{!_.isNull(item.expected_time) && !_.isEmpty(item.expected_time)
											? moment(item.expected_time).format('hh:mm A').split(' ')[1]
											: '-'}
									</Text>
								</div>
								<Text medium className={classes.task_sub_text}>
									Time
								</Text>
							</div>
							<div style={{ width: 58, height: 26 }}>
								<Text semi style={{ fontSize: 12, color: theme.colors.lightGrey2 }}>
									{constant.TRIP_STATUS_TEXT[item.status_id]}
								</Text>
							</div>
							<div className={classes.iconsUpDown}>
								{item.task_type === constant.TASK_TYPES.DROP.id ? (
									<img src={ImageLinks.chevron_down_solid_purple} width='16' height='16' alt='' />
								) : (
									<img src={ImageLinks.chevron_up_solid_green} width='16' height='16' alt='' />
								)}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ActiveTaskList;
