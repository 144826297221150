import React, { useContext, useState } from 'react';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'resources/ImageLinks';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import ContainedButton from 'modules/ContainedButton';
import DashboardContext from '../context';
import ConfirmClose from 'modules/ConfirmClose';
import moment from 'moment';
import RouteUploadModal from './RouteUploadModal';
import constant from 'resources/constant';

const useStyles = makeStyles((theme) => ({
	item_wrapper: {
		padding: 12,
		paddingBottom: 18,
		backgroundColor: `rgba(255, 255, 255, 0.60)`,
		border: `1px solid ${theme.colors.white}`,
		borderRadius: 6,
		marginTop: 12,
	},
	tab_item_wrap: {
		flex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 33,
		borderRadius: 8,
	},
	empty_linked_wrap: { flexDirection: 'column', marginTop: 20 },
	underline_text: { fontSize: 12, color: theme.colors.primary, textDecoration: 'underline', marginRight: 4 },
	route_item_wrap: {
		padding: 8,
		borderRadius: 6.22,
		backgroundColor: theme.colors.white,
		boxShadow: '0px 2.07353px 4.14706px 0px rgba(196, 196, 196, 0.30)',
		marginBottom: 8,
	},
	uplaod_box: {
		border: `1px dashed ${theme.colors.light_gray2}`,
		borderRadius: 10,
		backgroundColor: theme.colors.white,
		height: 146,
		flexDirection: 'column',
	},
	text_separator: { fontSize: 10, color: theme.colors.gray, marginLeft: 4, marginRight: 4 },
	empty_desc: { fontSize: 12, color: theme.colors.gray },
	import_btn: { borderRadius: 30, marginTop: 12, border: `1px solid ${theme.colors.primary}`, marginRight: 8 },
}));

const route_options = [
	{ key: 'rider', title: 'Assignment' },
	{ key: 'routes', title: 'Routes' },
];

const RouteWidget = () => {
	const classes = useStyles();
	const {
		routes_list,
		confirm_close_modal,
		assignment_list,
		handle_route_delete,
		handle_confirm_modal_close,
		fetch_route_items,
		selected_route_list_item_index,
		handle_close_route_list,
		app,
	} = useContext(DashboardContext);

	const [selected_tab, set_selected_tab] = useState(route_options[0].key);
	const [show_upload_modal, set_show_upload_modal] = useState(false);

	return (
		<div className={classes.item_wrapper}>
			<div className='d-flex'>
				{_.map(route_options, (route_item, route_index) => (
					<div
						key={`route_item_${route_index}`}
						onClick={() => {
							set_selected_tab(route_item.key);
							handle_close_route_list();
						}}
						className={clsx(classes.tab_item_wrap, 'cursor-pointer')}
						style={{
							backgroundColor: selected_tab === route_item.key ? theme.colors.light_primary : 'transparent',
						}}>
						<Text bold style={{ fontSize: 14, color: selected_tab === route_item.key ? theme.colors.light_pink : theme.colors.light_pink2 }}>
							{route_index == 0 && app.entity_type == 'rider' ? 'Active Task' : route_item.title}
						</Text>
					</div>
				))}
			</div>

			{assignment_list.length === 0 && selected_tab === 'rider' && app.entity_type == 'fleet' && (
				<div className={clsx(classes.empty_linked_wrap, 'd-flex align_item_center')}>
					<img src={ImageLinks.empty_assignment} alt='empty_rider_routes' />
					<Text semi style={{ fontSize: 14 }}>
						Create a reference route for Trace
					</Text>
					{/* <Text medium className={classes.empty_desc} style={{ marginTop: 4 }}>
						Assign orders to vehicle liked rider
					</Text> */}
					<div
						style={{ marginTop: 14, marginBottom: 6 }}
						className='d-flex align_item_center cursor-pointer'
						onClick={() => {
							const url = constant.BOP_URL + 'fleet-management';
							window.open(url, '_blank');
						}}>
						<Text semi className={classes.underline_text}>
							Go to Fleet
						</Text>
						<img src={ImageLinks.arrow_right_purple} width={12} height={12} alt='arrow_purple' />
					</div>
				</div>
			)}

			{selected_tab === 'rider' && app.entity_type == 'rider' && (
				<div style={{ textAlign: 'center', display: 'flex', marginTop: 20, alignItems: 'center', gap: 8 }}>
					<Text semi style={{ fontSize: 14, color: theme.colors.gray }}>
						View riders tasks the right panel
					</Text>
					<img src={ImageLinks.arrow_right_black} />
				</div>
			)}

			{assignment_list.length > 0 && selected_tab === 'rider' && (
				<div style={{ marginTop: 10 }}>
					{_.map(assignment_list, (item, index) => (
						<div
							className={clsx(classes.route_item_wrap, 'cursor-pointer')}
							style={{
								backgroundColor: selected_route_list_item_index === index ? theme.colors.light_purple : theme.colors.white,
								border: `${selected_route_list_item_index === index ? 1 : 0}px solid ${theme.colors.primary}`,
							}}
							key={`route_list_item_${index}`}
							onClick={() => fetch_route_items(index)}>
							<div className='d-flex align_item_center'>
								<img src={ImageLinks.upload_light_purple} width={12} height={12} alt='uplaod' />
								<Text bold style={{ fontSize: 12, marginLeft: 4 }}>
									{moment(item.start_time).format('YYYY-MM-DD hh:mm A')}
								</Text>
							</div>

							<div className='d-flex align_item_center' style={{ marginTop: 8 }}>
								<div className='d-flex '>
									<img src={ImageLinks.marker_arrow_green} alt='marker' />
									<Text bold style={{ fontSize: 12 }}>
										{_.get(item, 'route_meta.pickup_cnt', 0)}
									</Text>
								</div>
								<Text medium className={classes.text_separator}>
									|
								</Text>
								<div className='d-flex '>
									<img src={ImageLinks.marker_arrow_purple} alt='marker' />
									<Text bold style={{ fontSize: 12 }}>
										{_.get(item, 'route_meta.drop_cnt', 0)}
									</Text>
								</div>
							</div>
						</div>
					))}
				</div>
			)}

			{routes_list.length === 0 && selected_tab === 'routes' && (
				<div className={clsx(classes.empty_linked_wrap, 'd-flex align_item_center')}>
					<img src={ImageLinks.empty_routes} width={70} height={70} alt='empty_routes' />
					<Text semi style={{ fontSize: 14, marginTop: 8 }}>
						Track Vehicle with Routes
					</Text>
					<Text medium className={classes.empty_desc} style={{ marginTop: 2 }}>
						See pre-planned routes on live vehicle movement
					</Text>
					<div className='d-flex align_item_center'>
						{/* <ContainedButton disableElevation className={classes.import_btn} style={{ backgroundColor: theme.colors.white }}>
							<img src={ImageLinks.download_circle_purple} />
							<Text semi style={{ fontSize: 12, color: theme.colors.primary, marginLeft: 6 }}>
								Import
							</Text>
						</ContainedButton> */}

						<ContainedButton disableElevation style={{ borderRadius: 30, marginTop: 12 }} onClick={() => set_show_upload_modal(true)}>
							<img src={ImageLinks.upload_white} />
							<Text semi style={{ fontSize: 12, marginLeft: 6 }}>
								Upload
							</Text>
						</ContainedButton>
					</div>
				</div>
			)}

			{routes_list.length > 0 && selected_tab === 'routes' && (
				<div style={{ marginTop: 10 }}>
					<div className='flex_between_center' style={{ borderBottom: `1px solid ${theme.colors.light_gray}`, height: 26 }}>
						<Text semi style={{ fontSize: 14 }}>
							{routes_list.length} routes
						</Text>
						<div className='d-flex align_item_center cursor-pointer' onClick={() => set_show_upload_modal(true)}>
							<img src={ImageLinks.upload_purple} width={12} height={12} alt='refresh' style={{ marginRight: 4 }} />
							<Text semi className={classes.underline_text}>
								Upload New
							</Text>
						</div>
					</div>

					{_.map(routes_list, (item, index) => {
						const id_exist = _.filter(assignment_list, (assign_item) => assign_item.route_id === item.route_id).length > 0;
						return (
							<div
								className={clsx(classes.route_item_wrap, 'cursor-pointer')}
								style={{
									backgroundColor: selected_route_list_item_index === index ? theme.colors.light_purple : theme.colors.white,
									border: `${selected_route_list_item_index === index ? 1 : 0}px solid ${theme.colors.primary}`,
								}}
								key={`route_list_item_${index}`}
								onClick={() => fetch_route_items(index)}>
								<div className='d-flex flex_between_center'>
									<div className='d-flex align_item_center'>
										<img src={ImageLinks.upload_light_purple} width={12} height={12} alt='uplaod' />
										<Text bold style={{ fontSize: 12, marginLeft: 4 }}>
											{moment(item.routes[0].created_at).format('YYYY-MM-DD hh:mm A')}
										</Text>
									</div>
									<img
										style={{ opacity: id_exist ? 0.3 : 1 }}
										className={'cursor-pointer'}
										src={ImageLinks.delete_red}
										alt='delete'
										onClick={(e) => {
											if (!id_exist) handle_route_delete(e, item);
										}}
									/>
								</div>

								<div className='d-flex align_item_center' style={{ marginTop: 8 }}>
									<div className='d-flex '>
										<img src={ImageLinks.marker_arrow_green} alt='marker' />
										<Text bold style={{ fontSize: 12 }}>
											{_.filter(item.routes, (item) => item.point_type === 1).length}
										</Text>
									</div>
									<Text medium className={classes.text_separator}>
										|
									</Text>
									<div className='d-flex '>
										<img src={ImageLinks.marker_arrow_purple} alt='marker' />
										<Text bold style={{ fontSize: 12 }}>
											{_.filter(item.routes, (item) => item.point_type === 2).length}
										</Text>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			)}
			{confirm_close_modal.open && (
				<ConfirmClose
					headerTitle={confirm_close_modal.header_title}
					title={confirm_close_modal.title}
					close={handle_confirm_modal_close}
					positive_btn_text={confirm_close_modal.positive_text}
					negative_btn_text={confirm_close_modal.negative_text}
					titleStyles={{ fontSize: 16, textAlign: 'left' }}
					on_confirm={confirm_close_modal.confirm_acion}
					is_error_msg={confirm_close_modal.is_error_msg}
					modal_icon={confirm_close_modal.modal_icon}
					show_negative_btn={confirm_close_modal.show_negative_btn}
				/>
			)}

			{show_upload_modal && <RouteUploadModal close={() => set_show_upload_modal(false)} />}
		</div>
	);
};
export default RouteWidget;
