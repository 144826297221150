import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import qs from 'qs';

import { hide_loader, show_loader } from 'actions/app';
import api_requests from 'resources/api_requests';
import resource from './sample_routes.csv';
import ImageLinks from 'resources/ImageLinks';
import Routes from 'resources/Routes';

const initial_confirm_modal_details = {
	open: false,
	header_title: '',
	title: '',
	positive_text: '',
	negative_text: '',
	confirm_acion: () => {},
	is_error_msg: false,
	modal_icon: null,
	show_negative_btn: true,
};

const useDashboard = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { app } = useSelector((state) => state, shallowEqual);
	const { selected_widget, start_date_time, end_date_time } = app;

	const [show_date_range_modal, set_show_date_range_modal] = useState(false);

	const [selected_date, set_selected_date] = useState(moment(start_date_time).format('YYYY-MM-DD'));
	const [entity_details, set_entity_details] = useState(null);
	const [fleet_stats, set_fleet_stats] = useState(null);
	const [new_location, set_new_location] = useState({});
	const [sampled_location, set_sampled_location] = useState({});
	const [marker_position, set_marker_position] = useState(['28.6139', '77.2090']);
	const [marker_speed, set_marker_speed] = useState('');
	const [slider_value, set_slider_value] = useState(0);
	const [alert_list, set_alert_list] = useState([]);
	const [interval_groups, set_interval_groups] = useState([]);
	const [selected_interval_index, set_selected_interval_index] = useState(null);
	const [center_position, set_center_position] = useState(['28.6139', '77.2090']);
	const [zoom, set_zoom] = useState(10);
	const [routes_list, set_routes_list] = useState([]);
	const [assignment_list, set_assignment_list] = useState([]);
	const [route_item_list, set_route_item_list] = useState({});
	const [selected_route_list_item_index, set_selected_route_list_item_index] = useState(-1);
	const [confirm_close_modal, set_confirm_close_modal] = useState(_.cloneDeep(initial_confirm_modal_details));
	const [selected_route_order_item, set_selected_route_order_item] = useState(null);
	const [rider_active_task, set_rider_active_task] = useState({});
	const [rider_completed_tasks, set_rider_completed_tasks] = useState({});
	const slider_val_ref = useRef(0);
	const timer_ref = useRef(null);
	const map_ref = useRef(null);
	const selected_date_ref = useRef(moment(start_date_time).format('YYYY-MM-DD'));

	useEffect(() => {
		if (app.entity_type == 'fleet') {
			fetch_vehicle_info();
		} else {
			fetch_rider_info();
		}
	}, []);

	useEffect(() => {
		set_selected_route_order_item(null);
	}, [selected_route_list_item_index]);

	useEffect(() => {
		let _interval_groups = {};
		alert_list.forEach((item) => {
			const interval = round_to_15_minutes(moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss'));
			if (!_interval_groups[interval]) {
				_interval_groups[interval] = [];
			}
			_interval_groups[interval].push(item);
		});
		set_interval_groups(
			_.map(_interval_groups, (item, key) => {
				return {
					key,
					value: item,
				};
			}),
		);
	}, [alert_list]);

	useEffect(() => {
		set_route_item_list({});
		fetch_stats();
		fetch_location();
		fetch_configured_routes();
		set_selected_route_list_item_index(-1);
		map_ref.current?.setZoom(10);
		attach_timer();
	}, [start_date_time, end_date_time, entity_details]);

	useEffect(() => {
		if (_.includes(selected_widget, 'route') && app.entity_type == 'rider') {
			get_rider_tasks();
		} else {
			set_rider_active_task({});
		}
		set_zoom(10);
		set_selected_route_order_item(null);
	}, [selected_widget, start_date_time, selected_date]);
	// Define a function to round timestamps to the nearest 15-minute interval
	function round_to_15_minutes(timestamp) {
		const date = new Date(timestamp);
		const minutes = date.getMinutes();
		const rounded_minutes = Math.floor(minutes / 15) * 15;
		date.setMinutes(rounded_minutes, 0, 0);
		return date;
	}

	const attach_timer = () => {
		clearInterval(timer_ref.current);

		timer_ref.current = setInterval(() => {
			fetch_stats();
		}, 120000);
	};

	const fetch_vehicle_info = async () => {
		try {
			const response = await api_requests.get_fleet_single_vehicle(app.entity_id);
			if (response.success) {
				set_entity_details({ ...response.data, entity_name: response.data.registration_number });
			}
		} catch (error) {}
	};
	const fetch_rider_info = async () => {
		try {
			const response = await api_requests.get_fleet_single_rider(app.entity_id);
			if (response.success) {
				set_entity_details({
					...response.data.details,
					entity_name: response.data.details.rider_name,
					vehicle_type: '2 Wheeler',
					delivery_stats: response.data.delivery_stats,
					shift: response.data.shift,
				});
			}
		} catch (error) {}
	};
	const get_rider_tasks = async () => {
		try {
			let data;
			const completed_task = await api_requests.get_rider_completed_task_list(app.entity_id, selected_date);
			if (completed_task?.data?.tasks) {
				data = completed_task.data.tasks;
			}
			if (moment(selected_date).format('DD-MM-YYYY') == moment().format('DD-MM-YYYY')) {
				const response = await api_requests.get_rider_tasks(app.entity_id);
				if (response.data.routes) {
					data = data.concat(response.data.routes);
				}
			}

			const combined_response = {
				routes: data,
			};
			set_rider_active_task(combined_response);
		} catch (err) {}
	};

	const fetch_stats = async () => {
		if (!entity_details) return;
		try {
			const response = await api_requests.get_trace_stats(entity_details.trace_entity_id, {
				start_time: start_date_time,
				end_time: end_date_time,
			});
			set_fleet_stats(response.data);
			// if (response.data.currentLocation && is_today_date) {
			// 	set_marker_position([response.data.currentLocation.latitude, response.data.currentLocation.longitude]);
			// 	set_center_position([response.data.currentLocation.latitude, response.data.currentLocation.longitude]);
			// }
		} catch (error) {}
	};

	const time_to_minutes = (time) => {
		const [hours, minutes] = time.split(':').map(Number);
		return hours * 60 + minutes;
	};

	const fetch_location = async () => {
		if (!entity_details) return;
		try {
			dispatch(show_loader());
			const response = await api_requests.get_trace_location(entity_details.trace_entity_id, {
				start_time: start_date_time,
				end_time: end_date_time,
			});
			// const result = [];
			const date_wise_locations = {};
			for (let i = 0; i < response.data.locations.length; i++) {
				const element = response.data.locations[i];
				const time_24_hr = moment(element.location_at).format('HH:mm');
				const date_format = moment(element.location_at).format('YYYY-MM-DD');
				const min_equivalent = time_to_minutes(time_24_hr);
				const obj = {
					...element,
					min_equivalent,
				};
				if (date_wise_locations.hasOwnProperty(date_format)) date_wise_locations[date_format].push(obj);
				else date_wise_locations[date_format] = [obj];
				// result.push(obj);
			}
			set_new_location(date_wise_locations);
			sample_location_data(date_wise_locations);
			dispatch(hide_loader());
			let first_item = date_wise_locations[selected_date]?.[0];
			const keys_arr = Object.keys(date_wise_locations);
			if (_.isEmpty(first_item)) {
				first_item = date_wise_locations[keys_arr[0]]?.[0];
			}
			if (first_item) {
				set_marker_position([first_item.latitude, first_item.longitude]);
				set_center_position([first_item.latitude, first_item.longitude]);
				set_marker_speed(first_item.speed);
				set_slider_value(first_item.min_equivalent);
				slider_val_ref.current = first_item.min_equivalent;
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const sample_location_data = (data) => {
		const SAMPLE_SIZE = 60; // in seconds
		const obj = {};

		for (const property in data) {
			const data_array = data[property];
			const result = [];

			let curent_interval_start = moment(data_array[0].location_at);
			let next_interval_id = moment(curent_interval_start).add(SAMPLE_SIZE, 'seconds');

			for (let i = 0; i < data_array.length; i++) {
				const current_time = moment(data_array[i].location_at);

				if (current_time.isSameOrAfter(next_interval_id)) {
					// Move to the next interval
					curent_interval_start = next_interval_id;
					next_interval_id = moment(curent_interval_start).add(SAMPLE_SIZE, 'seconds');

					// Add the point from the next interval
					result.push(data_array[i]);
				}
			}

			obj[property] = result;
		}

		set_sampled_location(obj);
		// return result;
	};

	const handle_slider_change = (event, newValue) => {
		set_slider_value(newValue);
		slider_val_ref.current = newValue;

		const fleet_location = new_location[selected_date] || [];

		const filtered = _.find(fleet_location, (item) => item.min_equivalent >= newValue && item.min_equivalent <= newValue);
		if (filtered) {
			set_marker_position([filtered.latitude, filtered.longitude]);
			set_center_position([filtered.latitude, filtered.longitude]);
			set_marker_speed(filtered.speed);
		}
	};

	const fetch_alert_list = async () => {
		try {
			const res = await api_requests.get_alert_list(entity_details.trace_entity_id, {
				start_time: start_date_time,
				end_time: end_date_time,
			});
			set_selected_interval_index(null);
			set_alert_list(res.data);
		} catch (err) {}
	};

	const handle_alert_marker_click = (index) => {
		let position = null;
		const interval = round_to_15_minutes(moment(alert_list[index].timestamp).format('YYYY-MM-DD HH:mm:ss'));
		_.forEach(interval_groups, (item, index) => {
			if (item.key == interval) {
				position = index;
			}
		});
		if (position) set_selected_interval_index(position);
	};

	const fetch_configured_routes = async () => {
		try {
			const response = await api_requests.get_routes_list(entity_details.trace_entity_id, {
				start_time: start_date_time,
				end_time: end_date_time,
			});
			if (response.data && response.data.length > 0 && app.entity_type == 'fleet') {
				const ids_arr = _.map(response.data, (item) => item.route_id);
				const ids_str = ids_arr.join(',');
				const assign_response = await api_requests.get_route_assignments(entity_details.id, {
					route_id: ids_str,
				});
				if (assign_response.success) set_assignment_list(assign_response.data.data);
			}
			set_routes_list(response.data);
		} catch (err) {}
	};

	const handle_sample_csv_download = () => {
		const link = document.createElement('a');
		link.href = resource;
		let fileName = `sample_routes.csv`;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
	};

	const handle_csv_upload = async (data) => {
		try {
			const response = await api_requests.create_routes(data);
			fetch_configured_routes();
			toast.success('Uploaded Successfully', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		} catch (error) {}
	};

	const handle_route_delete = (e, item) => {
		e.stopPropagation();
		set_confirm_close_modal({
			open: true,
			header_title: 'Delete Route',
			title: 'Are you sure you want to remove route from vehicle?',
			positive_text: 'Confirm',
			negative_text: 'Cancel',
			confirm_acion: () => {
				handle_confirm_route_delete(item.route_id);
			},
			is_error_msg: true,
			modal_icon: ImageLinks.delete_red_ripple,
			show_negative_btn: true,
		});
	};

	const handle_confirm_route_delete = async (id) => {
		try {
			const response = await api_requests.delete_route(entity_details.trace_entity_id, id);
			fetch_configured_routes();
			set_selected_route_list_item_index(-1);
			set_route_item_list({});
			set_zoom(10);
			set_center_position(marker_position);
		} catch (error) {}
	};

	const handle_confirm_modal_close = () => {
		set_confirm_close_modal(_.cloneDeep(initial_confirm_modal_details));
	};

	const fetch_route_items = async (index) => {
		const item = routes_list[index];
		set_selected_route_list_item_index(index);
		try {
			const response = await api_requests.get_route_item_list(entity_details.trace_entity_id, item.route_id, {
				start_time: start_date_time,
				end_time: end_date_time,
			});
			const route_data = response.data[0];
			const routes = _.cloneDeep(route_data.routes);
			if (routes[0]?.sequence) {
				const sorted_routes = _.sortBy(routes, 'sequence');
				route_data.routes = sorted_routes;
			}
			set_route_item_list(route_data);
			set_zoom(7);
			if (route_data.routes.length > 0) {
				set_center_position([route_data.routes[0].latitude, route_data.routes[0].longitude]);
			}
		} catch (error) {}
	};

	const handle_close_route_list = () => {
		set_selected_route_list_item_index(-1);
		set_route_item_list({});
	};

	const handle_date_filter = (_start, _end) => {
		const existing_filters = qs.parse(history.location.search.slice(1));
		const new_filters = { ...existing_filters, start_date_time: moment(_start).toISOString(), end_date_time: moment(_end).toISOString() };
		history.push(Routes.DASHBOARD.path + '?' + qs.stringify({ ...new_filters }));
		window.location.reload(true);
	};

	const handle_timeline_date_change = (date) => {
		set_selected_date(date);
		selected_date_ref.current = date;
		slider_val_ref.current = 0;
		set_slider_value(0);
		const first_item = new_location[selected_date]?.[0];
		if (first_item) {
			set_marker_position([first_item.latitude, first_item.longitude]);
			set_center_position([first_item.latitude, first_item.longitude]);
			set_marker_speed(first_item.speed);
			// set_slider_value(first_item.min_equivalent);
			// slider_val_ref.current = first_item.min_equivalent;
		}
	};
	return {
		selected_widget,
		selected_date,
		entity_details,
		fleet_stats,
		marker_position,
		handle_slider_change,
		slider_value,
		set_slider_value,
		set_marker_position,
		center_position,
		set_center_position,
		slider_val_ref,
		time_to_minutes,
		marker_speed,
		set_marker_speed,
		map_ref,
		fetch_alert_list,
		alert_list,
		interval_groups,
		set_alert_list,
		selected_interval_index,
		set_selected_interval_index,
		zoom,
		set_zoom,
		handle_alert_marker_click,
		handle_sample_csv_download,
		handle_csv_upload,
		fetch_configured_routes,
		routes_list,
		handle_route_delete,
		confirm_close_modal,
		handle_confirm_modal_close,
		fetch_route_items,
		selected_route_list_item_index,
		route_item_list,
		handle_close_route_list,
		selected_route_order_item,
		set_selected_route_order_item,
		show_date_range_modal,
		set_show_date_range_modal,
		start_date_time,
		end_date_time,
		set_selected_date,
		handle_date_filter,
		new_location,
		sampled_location,
		handle_timeline_date_change,
		assignment_list,
		selected_date_ref,
		rider_active_task,
		app,
	};
};

export default useDashboard;
