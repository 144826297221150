import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		width: 32,
		height: 32,
		padding: 4,
		borderRadius: 4,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2.0202px 4.0404px 0px rgba(196, 196, 196, 0.30)`,
		border: `1px solid ${theme.colors.light_white}`,
		marginRight: 8,
	},
}));

const IconWrapper = ({ icon, wrapper_styles = {} }) => {
	const classes = useStyles();
	return (
		<div className={classes.wrapper} style={wrapper_styles}>
			<img src={icon} alt='icon' />
		</div>
	);
};

export default IconWrapper;
