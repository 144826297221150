import React, { useContext, useMemo, useRef, useEffect } from 'react';
import { Circle, MapContainer, Polyline, TileLayer, useMap, useMapEvents } from 'react-leaflet';

import L, { DivIcon } from 'leaflet';
import _ from 'lodash';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import { Marker } from 'react-leaflet/Marker';
import { Popup } from 'react-leaflet/Popup';
import 'leaflet-providers/leaflet-providers.js'; // Import Leaflet Providers

import '../style.css';
import theme from 'resources/theme';
import ImageLinks from 'resources/ImageLinks';
import DashboardContext from '../context';
import Text from 'modules/Text';

var myIcon = L.icon({
	iconUrl: ImageLinks.truck_purple_circle_ripple,
	iconSize: [60, 60],
});

var alert_icon = L.icon({
	iconUrl: ImageLinks.exclamation_solid_red,
	iconSize: [16, 16],
});

const options = {
	noClip: false,
	color: theme.colors.light_pink2,
	stroke: true,
	weight: 5,
};

const circle_options = { fillColor: theme.colors.light_primary, color: theme.colors.primary, stroke: 5, dashArray: '10,5' };

const MemoizedCanvasLayerComponent = ({ map, locArray, handlePointClick }) => {
	const circleLayerRef = useRef(null);
	const memoizedLocArray = useRef(null);
	const arr_length = useRef(0);

	useEffect(() => {
		if (memoizedLocArray.current === locArray && memoizedLocArray.current?.length === arr_length.current) return;
		arr_length.current = locArray.length;
		memoizedLocArray.current = locArray;

		if (circleLayerRef.current) {
			map.removeLayer(circleLayerRef.current);
		}

		const circles = locArray.map((item) => {
			const circle = L.circle([item.latitude, item.longitude], {
				color: theme.colors.primary, // Change the color or other options as needed
				fillColor: theme.colors.light_primary, // Change the color or other options as needed
				fillOpacity: 0.5,
				radius: 40,
			});

			circle.on('click', () => {
				handlePointClick(null, item);
			});

			return circle;
		});
		circleLayerRef.current = L.layerGroup(circles).addTo(map);
	}, [map, locArray, handlePointClick]);

	return null;
};
const TraceMap = () => {
	const {
		marker_position,
		new_location,
		sampled_location,
		selected_date,
		marker_speed,
		map_ref,
		set_slider_value,
		set_marker_position,
		set_center_position,
		slider_val_ref,
		alert_list,
		center_position,
		zoom,
		set_zoom,
		handle_alert_marker_click,
		route_item_list,
		selected_route_order_item,
		app,
		rider_active_task,
	} = useContext(DashboardContext);

	useEffect(() => {
		if (app.entity_type == 'rider') {
			myIcon = L.icon({
				iconUrl: ImageLinks.rider_purple_circle,
				iconSize: [30, 30],
			});
		}
	}, []);

	const handle_point_click = (event, item) => {
		slider_val_ref.current = item.min_equivalent;
		set_slider_value(item.min_equivalent);
		set_marker_position([item.latitude, item.longitude]);
		set_center_position([item.latitude, item.longitude]);
	};

	function ChangeView({ center, zoom }) {
		const map = useMap();
		map.setView(center, zoom);
		// map.setZoom(zoom);
		return null;
	}

	function MapScrollEvent() {
		const map_events = useMapEvents({ zoomend: () => set_zoom(map_ref.current?.getZoom()) });
	}

	const is_sequence_available = _.get(route_item_list, 'routes.0.sequence', null) > 0;

	const loc_array = useMemo(() => {
		let original_loc = new_location[selected_date] || [];
		const copied = _.cloneDeep(sampled_location);
		delete copied[selected_date];
		if (!_.isEmpty(copied)) {
			for (const key in copied) {
				const val = copied[key];
				original_loc.push(...val);
			}
		}
		return original_loc;
	}, [new_location, selected_date, sampled_location]);
	return (
		<div style={{ height: '100%', width: '100%' }}>
			{/* @TODO Set map center according to bound */}
			<MapContainer
				ref={map_ref}
				center={{ lat: marker_position[0], lng: marker_position[1] }}
				zoom={10}
				scrollWheelZoom={true}
				// bounds={bounds}
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
				/>
				<ChangeView center={{ lat: center_position[0], lng: center_position[1] }} zoom={zoom} />
				<MapScrollEvent />
				{loc_array.length > 0 && !_.isEmpty(map_ref.current) && (
					<MemoizedCanvasLayerComponent map={map_ref.current} locArray={loc_array} handlePointClick={handle_point_click} />
				)}
				<Marker icon={myIcon} position={marker_position}>
					<Popup>
						<div className='d-flex align_item_center' style={{ height: 20 }}>
							<img src={ImageLinks.speedometer_purple} alt='speed_purple' />
							<Text semi style={{ fontSize: 14, color: theme.colors.primary, marginLeft: 4 }}>
								{marker_speed ? `${marker_speed} kmph` : 'NA'}
							</Text>
						</div>
					</Popup>
				</Marker>
				{!_.isEmpty(route_item_list) && is_sequence_available && (
					<Polyline positions={[_.map(route_item_list.routes, (item) => [item.latitude, item.longitude])]} pathOptions={options} />
				)}
				{!_.isEmpty(rider_active_task.routes) && (
					<Polyline
						positions={[_.map(rider_active_task.routes, (item) => [item.task_location.latitude, item.task_location.longitude])]}
						pathOptions={options}
					/>
				)}
				{!_.isEmpty(route_item_list) &&
					_.map(route_item_list.routes, (item, index) => {
						const task_marker = new DivIcon({
							html: `<div>
							<img src="${ImageLinks.marker_purple}" width="25px" height="25px" />
							${is_sequence_available ? `<span style="position:absolute;color:white;top:2px;font-size:12px;width:20px;text-align:center">${item.sequence}</span>` : ''}
							</div>`,
							iconSize: [20, 20],
						});

						return (
							<>
								{!_.isEmpty(selected_route_order_item) && selected_route_order_item.id === item.id && (
									<Circle center={[item.latitude, item.longitude]} pathOptions={circle_options} radius={item.geofence} />
								)}
								<Marker key={`route_marker_item_${index}`} icon={task_marker} position={[item.latitude, item.longitude]}></Marker>;
							</>
						);
					})}
				{!_.isEmpty(rider_active_task) &&
					_.map(rider_active_task.routes, (item, index) => {
						const task_marker = new DivIcon({
							html: `<div>
							<img src="${ImageLinks.marker_purple}" width="25px" height="25px" />
							${`<span style="position:absolute;color:white;top:2px;font-size:12px;width:20px;text-align:center">${index + 1}</span>`}
							</div>`,
							iconSize: [20, 20],
						});

						return (
							<>
								{!_.isEmpty(selected_route_order_item) && selected_route_order_item.id === item.id && (
									<Circle center={[item.task_location.latitude, item.task_location.longitude]} pathOptions={circle_options} radius={1000} />
								)}
								<Marker
									key={`route_marker_item_${index}`}
									icon={task_marker}
									position={[item.task_location.latitude, item.task_location.longitude]}></Marker>
								;
							</>
						);
					})}
				{_.map(alert_list, (item, index) => {
					if (!item.data?.location?.latitude || !item.data?.location?.longitude) return;
					return (
						<Marker
							key={`alert_marker_item_${index}`}
							icon={alert_icon}
							position={[item.data.location.latitude, item.data.location.longitude]}
							eventHandlers={{
								click: () => {
									handle_alert_marker_click(index);
								},
							}}></Marker>
					);
				})}
			</MapContainer>
		</div>
	);
};

export default TraceMap;
