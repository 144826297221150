import React, { useEffect, useContext, useMemo, useRef, useState } from 'react';
import { Slider, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import IconWrapper from 'modules/IconWrapper';
import Text from 'modules/Text';
import ImageLinks from 'resources/ImageLinks';
import theme from 'resources/theme';
import DashboardContext from '../context';
import moment from 'moment/moment';

const useStyles = makeStyles((theme) => ({
	absolute_wrapper: {
		position: 'absolute',
		bottom: 50,
		left: '50%',
		transform: 'translateX(-50%)', // Center the wrapper horizontally
		width: 1000, // Set a default minimum width
		zIndex: 401,
	},
	wrapper: {
		display: 'flex',
		flex: 1,
		borderRadius: 6,
		border: `1px solid ${theme.colors.white}`,
		height: 50,
		backgroundColor: `rgba(255, 255, 255, 0.60)`,
		alignItems: 'center',
		paddingLeft: 12,
		paddingRight: 12,
	},
	alert_wrap: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	alert_stick: {
		borderRadius: 10,
		width: 2,
		height: 10,
		backgroundColor: theme.colors.red,
		marginTop: -2,
	},
	multiplier_wrap: {
		width: 32,
		height: 32,
		padding: 4,
		borderRadius: 4,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 2.0202px 4.0404px 0px rgba(196, 196, 196, 0.30)`,
		border: `1px solid ${theme.colors.light_white}`,
		marginLeft: 8,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	rail: {
		backgroundColor: theme.colors.light_gray,
		height: 4, // Adjust the height of the track,
		boxShadow: '0px 0px 1px 0px #9EBFB5 inset',
	},
	past_rail: {
		backgroundColor: theme.colors.light_pink2,
		height: 4, // Adjust the height of the track,
		boxShadow: '0px 0px 1px 0px #9EBFB5 inset',
	},
	thumb: {
		width: 12, // Adjust the width of the thumb
		height: 12, // Adjust the height of the thumb
		top: 13.5,
	},
	track: {
		height: 4, // Adjust the height of the track
		borderRadius: 20,
		backgroundColor: theme.colors.light_pink4,
	},
	hour_label: {
		fontSize: 10,
		color: theme.colors.gray,
	},
	custom_mark: {
		width: 30,
		border: 'none',
		background: 'transparent',
		position: 'absolute',
		zIndex: 10000,
		cursor: 'pointer',
		top: -2,
	},
	mark: {},
	date_pill: {
		width: 64,
		height: 26,
		borderRadius: 17,
		cursor: 'pointer',
		boxShadow: `0px 2.778px 5.556px 0px rgba(196, 196, 196, 0.30)`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const Timeline = () => {
	const {
		new_location,
		handle_slider_change,
		slider_value,
		set_slider_value,
		selected_date,
		set_marker_position,
		set_center_position,
		slider_val_ref,
		set_marker_speed,
		interval_groups,
		set_selected_interval_index,
		start_date_time,
		end_date_time,
		handle_timeline_date_change,
		selected_date_ref,
	} = useContext(DashboardContext);

	const [multiplier_val, set_multiplier_val] = useState(1);
	const [is_play, set_is_play] = useState(false);
	const timer_ref = useRef(null);
	const classes = useStyles();

	const hourLabels = Array.from({ length: 24 }, (_, index) => {
		const hour = index.toString().padStart(2, '0'); // Format as two-digit hour
		return `${hour}:00`;
	});

	const handle_multiplier = () => {
		let new_val = 1;
		switch (multiplier_val) {
			case 1:
				new_val = 2;
				break;
			case 2:
				new_val = 10;
				break;
			case 10:
				new_val = 1;
				break;
			default:
				new_val = 1;
				break;
		}
		set_multiplier_val(new_val);
		if (is_play) {
			clearInterval(timer_ref.current);
			start_timer(new_val);
			return;
		}
	};

	const handle_play_pause = () => {
		set_is_play((prev) => !prev);
		if (is_play) {
			clearInterval(timer_ref.current);
			return;
		}
		start_timer();
	};

	const start_timer = (val = multiplier_val) => {
		timer_ref.current = setInterval(() => {
			const new_val = slider_val_ref.current + val;
			if (new_val >= 1439) {
				// set_is_play(false);
				// clearInterval(timer_ref.current);
				const index_of = _.indexOf(between_dates_array, selected_date_ref.current);
				if (index_of < between_dates_array.length - 1) {
					handle_timeline_date_change(between_dates_array[index_of + 1]);
					return;
				} else {
					set_is_play(false);
					clearInterval(timer_ref.current);
				}
			}

			let index = -1,
				filtered = null;
			const fleet_location = new_location[selected_date_ref.current] || [];
			for (let i = 0; i < fleet_location.length - 1; i++) {
				const next_element = fleet_location[i + 1];
				const current_element = fleet_location[i];
				if (new_val >= current_element.min_equivalent && new_val < next_element.min_equivalent) {
					index = i;
					filtered = current_element;
					break;
				}
			}

			if (filtered) {
				set_marker_position([filtered.latitude, filtered.longitude]);
				set_center_position([filtered.latitude, filtered.longitude]);
				set_marker_speed(filtered.speed);
			}

			set_slider_value(new_val);
			slider_val_ref.current = new_val;
		}, 1000);
	};

	const is_past_date = moment(selected_date).isBefore(moment(), 'day');

	const between_dates_array = useMemo(() => {
		let result = [];

		const _start = moment(start_date_time);
		const _end = moment(end_date_time);

		if (_start.isAfter(_end)) {
			return [];
		}

		let current_date = _start.clone();

		while (current_date.isSameOrBefore(_end, 'day')) {
			result.push(current_date.format('YYYY-MM-DD'));
			current_date.add(1, 'day');
		}

		return result;
	}, [start_date_time, end_date_time]);

	return (
		<div className={classes.absolute_wrapper}>
			<div className='d-flex align_item_center' style={{ marginLeft: 20 }}>
				{_.map(between_dates_array, (item, index) => (
					<div className='d-flex align_item_center'>
						<div
							key={`dayw_${index}`}
							onClick={() => handle_timeline_date_change(item)}
							className={classes.date_pill}
							style={{
								backgroundColor: selected_date === item ? theme.colors.light_purple : theme.colors.white,
								border: `1px solid ${selected_date === item ? theme.colors.primary : theme.colors.light_white}`,
							}}>
							<Text semi style={{ fontSize: 12, color: selected_date === item ? theme.colors.primary : theme.colors.gray }}>
								{moment(item).format('DD MMM')}
							</Text>
						</div>
						{between_dates_array.length - 1 > index && (
							<div
								style={{
									height: 2,
									backgroundColor: _.indexOf(between_dates_array, selected_date) === index ? theme.colors.primary : theme.colors.light_gray2,
									width: 24,
								}}></div>
						)}
					</div>
				))}
			</div>
			<div className={classes.wrapper}>
				<div className='cursor-pointer' onClick={handle_play_pause}>
					<IconWrapper icon={is_play ? ImageLinks.pause_solid_purple : ImageLinks.play_solid_purple} />
				</div>
				<div style={{ flex: 1, position: 'relative' }}>
					<div style={{ width: '100%' }}>
						{_.map(_.reverse([...interval_groups]), (item, index) => {
							return (
								<div
									style={{ left: `${(moment(item.key).hour() * 60 + moment(item.key).minute()) / 14.4}%` }}
									key={`mark_${index}`}
									className={classes.custom_mark}
									onClick={(e) => {
										e.stopPropagation();
										set_selected_interval_index(interval_groups.length - 1 - index);
									}}>
									<img
										src={ImageLinks.exclamation_red_filled_marker}
										alt='Image 2'
										style={{ width: 12, height: 'auto', cursor: 'pointer' }}
									/>
								</div>
							);
						})}
					</div>
					<Slider
						aria-label='Timeline'
						value={slider_value}
						step={1}
						marks={false}
						min={0}
						max={1439} // Set the maximum value to the full range you want to display
						// onChange={handle_slider_change}
						onChangeCommitted={handle_slider_change}
						classes={{
							rail: is_past_date ? classes.past_rail : classes.rail,
							thumb: classes.thumb,
							track: classes.track,
							mark: classes.mark,
						}}
					/>

					<div style={{ display: 'flex', marginTop: -10 }}>
						{hourLabels.map((label) => (
							<Text semi key={label} className={classes.hour_label} style={{ width: `${100 / hourLabels.length}%` }}>
								{label}
							</Text>
						))}
					</div>
				</div>
				<div className={classes.multiplier_wrap} onClick={handle_multiplier}>
					<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
						{multiplier_val}
						<Text component={'span'} style={{ fontSize: 8 }}>
							X
						</Text>
					</Text>
				</div>
			</div>
		</div>
	);
};

export default Timeline;
