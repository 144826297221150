import React, { useContext, useState } from 'react';
import theme from 'resources/theme';
import Papa from 'papaparse';
import _ from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core';

import OutlinedButton from 'modules/OutlinedButton';
import Text from 'modules/Text';
import Upload from 'modules/Upload';
import resource from '../sample_routes.csv';
import ContainedButton from 'modules/ContainedButton';
import constant from 'resources/constant';
import ImageLinks from 'resources/ImageLinks';
import DashboardContext from '../context';

const useStyles = makeStyles(() => ({
	upload_csv_content_container: {
		width: '100%',
		height: 250,
		borderRadius: 10,
		border: `1px dashed ${theme.colors.light_gray2}`,
		display: 'flex',
		flexDirection: 'column',
	},
	upload_csv_file_information: {
		padding: '20px 16px',
		borderRadius: 4,
		backgroundColor: theme.colors.light_white,
		marginTop: 24,
		width: '100%',
	},
	upload_pending_file_information: {
		padding: '20px 16px',
		borderRadius: 4,
		marginTop: 24,
		width: '100%',
	},
	cross_button: {
		background: theme.colors.light_red,
		padding: '2px 8px',
		borderRadius: '50%',
		position: 'absolute',
		top: '-8px',
		right: '-8px',
		cursor: 'pointer',
	},
}));

const UploadRouteCSV = ({ file_data, set_file_data }) => {
	const { entity_details } = useContext(DashboardContext);
	const [upload_csv, set_upload_csv] = useState(constant.BTN_STATE.DEFAULT);
	const [progress, set_progress] = useState(0);
	const [error_msg, set_error_msg] = useState('');
	const [uploaded_csv_file, set_uploaded_csv_file] = useState({});
	const classes = useStyles();

	const formatBytes = (bytes, decimals = 2) => {
		if (!+bytes) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'Gb', 'Tb', 'PiB', 'EiB', 'ZiB', 'YiB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
	};

	const handle_csv = (fileInfo, inputRef) => {
		set_uploaded_csv_file(fileInfo);
		set_upload_csv(constant.BTN_STATE.LOADING);
		set_progress(0);
		setTimeout(() => {
			set_progress(20);
		}, 200);
		setTimeout(() => {
			set_progress(40);
		}, 400);
		setTimeout(() => {
			set_progress(60);
		}, 600);
		setTimeout(() => {
			set_progress(80);
		}, 800);
		setTimeout(() => {
			set_progress(100);
		}, 1000);

		Papa.parse(fileInfo.file, {
			header: false,
			skipEmptyLines: true,
			complete: (results) => {
				const data = results.data;
				const items = [];
				let invalid_message = '';
				const route_id = uuidv4();

				let sequence_present = false;
				for (let i = 1; i < data.length; i++) {
					const row_seq = data[i][7];
					if (!_.isEmpty(row_seq)) {
						sequence_present = true;
						break;
					}
				}

				_.map(data, (routes, key) => {
					if (key === 0 || !_.isEmpty(invalid_message)) {
						return;
					}

					const ref_id = routes[0];
					const poc_name = routes[1];
					const poc_number = routes[2];
					const address = routes[3];
					const lat_lng = routes[4];
					const point_type = routes[5];
					const geo_fence = routes[6];
					const sequence = routes[7];
					const eta = routes[8];

					if (_.isEmpty(ref_id)) {
						invalid_message = `Ref Id cannot be empty at row ${key + 1}`;
						return;
					}

					if (_.filter(items, (row_item) => row_item.ref_id == ref_id).length > 0) {
						invalid_message = `Reference Id duplicate at row ${key + 1}`;
						return;
					}

					if (_.isEmpty(poc_name)) {
						invalid_message = `Poc name cannot be empty at row ${key + 1}`;
						return;
					}

					if (_.isEmpty(poc_number)) {
						invalid_message = `Poc number cannot be empty at row ${key + 1}`;
						return;
					}

					if (poc_number.trim().length != 10) {
						invalid_message = `Poc number format incorrect at row ${key + 1}`;
						return;
					}

					if (_.isEmpty(address)) {
						invalid_message = `Address cannot be empty at row ${key + 1}`;
						return;
					}

					if (_.isEmpty(lat_lng)) {
						invalid_message = `Lat long cannot be empty at row ${key + 1}`;
						return;
					}

					if (_.isEmpty(point_type)) {
						invalid_message = `Point type cannot be empty at row ${key + 1}`;
						return;
					}

					if (sequence_present) {
						if (_.isEmpty(sequence)) {
							invalid_message = `Sequence empty at row ${key + 1}`;
							return;
						}

						if (_.filter(items, (row_item) => row_item.sequence == sequence).length > 0) {
							invalid_message = `Sequence duplicate at row ${key + 1}`;
							return;
						}
					}

					if (!_.isEmpty(eta)) {
						const is_valid = moment(eta, 'YYYY-MM-DD HH:mm:ss').isValid();
						if (!is_valid) {
							invalid_message = `Invalid Format of eta at row ${key + 1}`;
							return;
						}
					}

					if (_.isEmpty(geo_fence)) {
						invalid_message = `Geo fence cannot be empty at row ${key + 1}`;
						return;
					}

					const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
					if (!regex.test(lat_lng)) {
						invalid_message = `Invalid Format of coordinates at row ${key + 1}`;
						return;
					}

					const split_coords = lat_lng.split(',');
					const obj = {
						trace_entity_id: entity_details.trace_entity_id,
						route_id,
						ref_id,
						point_type: point_type.toLowerCase() === 'pickup' ? 1 : 2,
						latitude: split_coords[0].trim(),
						longitude: split_coords[1].trim(),
						address,
						geofence: geo_fence,
						sequence: !_.isEmpty(sequence) ? sequence : null,
						eta: !_.isEmpty(eta) ? moment(eta).toISOString() : null,
						poc: {
							name: poc_name,
							mobile: poc_number,
						},
					};

					items.push(obj);
				});

				if (!_.isEmpty(invalid_message) || items.length === 0) {
					set_error_msg(invalid_message ? invalid_message : 'Csv cannot be empty');
					set_upload_csv(constant.BTN_STATE.FAILED);
					set_uploaded_csv_file({});
					set_file_data([]);
					return;
				}

				set_error_msg('');
				set_upload_csv(constant.BTN_STATE.SUCCESS);
				set_file_data(items);
			},
		});
	};

	const download_file = () => {
		const link = document.createElement('a');
		link.href = resource;
		let fileName = `sample-csv.csv`;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
	};

	const handle_remove_file = () => {
		set_uploaded_csv_file({});
		set_file_data([]);
		set_error_msg('');
		set_progress(0);
	};

	return (
		<div>
			<div className={classes.upload_csv_content_container}>
				{upload_csv === 'success' && progress >= 100 ? (
					<div className={clsx('text-align-center')} style={{ padding: 32 }}>
						<img src={ImageLinks.tick_circle_green_solid} width={52} height={52} alt='green-tick' />
						<Text medium style={{ marginTop: 24, fontSize: 14 }}>
							CSV uploaded successfully!
						</Text>
						<div className={classes.upload_csv_file_information} style={{ position: 'relative' }}>
							<div className={classes.cross_button} onClick={handle_remove_file}>
								<img src={ImageLinks.cross_red} alt='cross' width={8} height={8} />
							</div>
							<div className='flex_between_center'>
								<div className='d-flex align_item_center'>
									<img src={ImageLinks.folder_light_purple} width={24} height={24} />
									<Text style={{ fontSize: 12, paddingLeft: 10, paddingRight: 8 }}>{_.get(uploaded_csv_file, 'name', '')}</Text>
									<Text bold style={{ fontSize: 12, color: theme.colors.primary }}>
										{file_data.length} Orders
									</Text>
								</div>
								<Text bold component={'div'} style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									{formatBytes(_.get(uploaded_csv_file, 'file.size', ''))}
								</Text>
							</div>
						</div>
					</div>
				) : upload_csv === 'failed' && progress >= 100 ? (
					<Upload accepted_extensions='.csv' add_image={handle_csv}>
						<div className={clsx('text-align-center')} style={{ padding: 32 }}>
							<img src={ImageLinks.exclamation_solid_red} width={52} height={52} style={{ background: theme.colors.red, borderRadius: '50%' }} />
							<Text medium style={{ marginTop: 24, fontSize: 14 }}>
								{`Unable to upload CSV.`}
							</Text>
							{!_.isEmpty(error_msg) && (
								<Text medium style={{ fontSize: 14 }}>
									{error_msg}
								</Text>
							)}
							<ContainedButton style={{ borderRadius: 30, marginTop: 12 }}>
								<Text bold style={{ fontSize: 14 }}>
									Upload Again
								</Text>
							</ContainedButton>
						</div>
					</Upload>
				) : progress < 100 && progress > 0 ? (
					<div className={clsx('text-align-center')} style={{ padding: 32 }}>
						<img src={ImageLinks.cricle_with_dashed_light_purple} width={52} height={52} alt='green-tick' />
						<Text medium style={{ marginTop: 24, fontSize: 14 }}>
							Uploading CSV
						</Text>
						<div className={classes.upload_pending_file_information}>
							<div className='flex_between_center'>
								<img src={ImageLinks.folder_light_purple} width={24} height={24} />
								<Text style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>{progress}%</Text>
							</div>
							<LinearProgress variant='determinate' value={progress} />
						</div>
					</div>
				) : (
					<Upload
						accepted_extensions='.csv'
						add_image={handle_csv}
						style={{
							flex: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
						}}>
						<img src={ImageLinks.upload_light_purple} width={52} height={52} alt='upload-image' />
						<Text medium style={{ fontSize: 12, marginTop: 24, marginBottom: 12 }}>
							Upload CSV file from computer
						</Text>
						<ContainedButton style={{ borderRadius: 30 }}>
							<Text semi style={{ fontSize: 14 }}>
								Select File
							</Text>
						</ContainedButton>
					</Upload>
				)}
			</div>
			<div className='flex_between_center' style={{ marginTop: 12 }}>
				<Text medium style={{ fontSize: 13, color: theme.colors.darkGrey2, marginRight: 5 }}>
					Please fill all fields. Put sequence numbers against all points in file to create a route
				</Text>
				<OutlinedButton onClick={download_file} style={{ height: 33, minWidth: 140 }}>
					<img src={ImageLinks.download_purple} alt='download-purple' width={16} height={16} />
					<Text semi style={{ fontSize: 14, marginLeft: 10 }}>
						CSV Format
					</Text>
				</OutlinedButton>
			</div>
		</div>
	);
};

export default UploadRouteCSV;
