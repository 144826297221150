import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Text from 'modules/Text';
import _ from 'lodash';
import moment from 'moment';
import TimeInput from 'modules/TimeInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UploadRouteCSV from './UploadRouteCSV';
import ContainedButton from 'modules/ContainedButton';
import ImageLinks from 'resources/ImageLinks';
import DashboardContext from '../context';

const useStyles = makeStyles((theme) => ({
	modal_wrap: { display: 'flex', outline: 'none' },
	container: {
		padding: 20,
		background: 'white',
		width: 580,
		height: 542,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	header_text: {
		color: theme.colors.darkGrey2,
		fontSize: 12,
		marginBottom: 6,
	},

	date_wrapper: {
		border: `1px solid ${theme.colors.light_primary}`,
		padding: '2px 8px',
		borderRadius: 36,
		display: 'flex',
		alignItems: 'center',
		gap: 3,
		marginRight: 10,
		height: 33,
		width: 120,
		marginBottom: 26,
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
	},
	calendar_text: { fontSize: 12, color: theme.colors.primary, width: '80%' },
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: `1px solid ${theme.colors.darkGrey2}`,
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: `1px solid ${theme.colors.darkGrey2}`,
		},
	},
}));

const RouteUploadModal = ({ close }) => {
	const { handle_csv_upload } = useContext(DashboardContext);
	const current_time = moment();
	const remainder = 15 - (current_time.minutes() % 15);
	const rounded_time = moment(current_time).add(remainder, 'minutes');
	const [start_date, set_start_date] = useState(new Date());
	const [start_time, set_start_time] = useState(rounded_time.format('HH:mm'));
	const [file_data, set_file_data] = useState([]);
	// const [end_date, set_end_date] = useState(new Date());
	// const [end_time, set_end_time] = useState('23:45');
	const classes = useStyles();

	const handle_confirm = async () => {
		const format_date = moment(start_date).format('YYYY-MM-DD');
		const date_time_str = `${format_date} ${start_time}`;
		const ios_str = moment(date_time_str).toISOString();
		const formatted_data = _.map(file_data, (item) => ({ ...item, date: ios_str }));
		handle_csv_upload(formatted_data);
		close();
	};

	return (
		<Modal open={true} onClose={close} className={classes.modal_wrap}>
			<div className={classes.container}>
				<div className={'d-flex'} style={{ justifyContent: 'space-between' }}>
					<div>
						<Text bold style={{ fontSize: 20 }}>
							Upload Route
						</Text>
						<Text medium style={{ fontSize: 12 }}>
							Create a reference route for vehicle
						</Text>
					</div>

					<img src={ImageLinks.cross_black} onClick={close} style={{ cursor: 'pointer' }} alt='close_modal' />
				</div>

				<div className='d-flex' style={{ gap: 20, marginTop: 26 }}>
					<div style={{ width: 185 }}>
						<div>
							<Text semi className={classes.header_text}>
								Start Date*
							</Text>
							<DatePicker
								dateFormat='yyyy-MM-dd'
								selected={start_date}
								onChange={(date) => {
									set_start_date(date);
								}}
								minDate={moment().toDate()}
								customInput={
									<div className={classes.date_wrapper}>
										<Text semi className={classes.calendar_text}>
											{moment(start_date).format('DD MMM `YY')}
										</Text>
										<img src={ImageLinks.calendar_filled_purple} alt='date' className='cursor-pointer' width={22} height={22} />
									</div>
								}
							/>
						</div>
						<div>
							<Text semi className={classes.header_text}>
								Start Time*
							</Text>
							<TimeInput value={start_time} handleChange={(val) => set_start_time(val)} />
						</div>

						{/* <div style={{ marginTop: 26 }}>
							<Text semi className={classes.header_text}>
								End Date*
							</Text>
							<DatePicker
								dateFormat='yyyy-MM-dd'
								selected={end_date}
								onChange={(date) => {
									set_end_date(date);
								}}
								minDate={moment().toDate()}
								customInput={
									<div className={classes.date_wrapper}>
										<Text semi className={classes.calendar_text}>
											{moment(end_date).format('DD MMM `YY')}
										</Text>
										<img src={ImageLinks.calendar_filled_purple} alt='date' className='cursor-pointer' width={22} height={22} />
									</div>
								}
							/>
						</div>
						<div>
							<Text semi className={classes.header_text}>
								Start Time*
							</Text>
							<TimeInput value={end_time} handleChange={(val) => set_end_time(val)} />
						</div> */}
					</div>
					<div style={{ width: 335 }}>
						<UploadRouteCSV file_data={file_data} set_file_data={set_file_data} />
					</div>
				</div>

				<ContainedButton
					disabled={file_data.length === 0}
					onClick={handle_confirm}
					style={{ borderRadius: 30, width: 120, alignSelf: 'center', marginTop: 40 }}>
					<Text semi style={{ fontSize: 16 }}>
						Confirm
					</Text>
				</ContainedButton>
			</div>
		</Modal>
	);
};

export default RouteUploadModal;
