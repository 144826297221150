import React, { useContext, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'resources/ImageLinks';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import IconWrapper from 'modules/IconWrapper';
import SelectOverviewWidgetModal from 'views/Dashboard/components/SelectOverviewWidgetModal';
import DashboardContext from '../context';

const useStyles = makeStyles((theme) => ({
	item_wrapper: {
		padding: 12,
		backgroundColor: `rgba(255, 255, 255, 0.60)`,
		border: `1px solid ${theme.colors.white}`,
		borderRadius: 6,
		marginBottom: 4,
	},
	avatar_wrap: {
		width: 48,
		height: 48,
		borderRadius: 28,
		backgroundColor: theme.colors.white,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

// const overview_options = [
// 	{ key: 'fuel', title: 'Fuel Usage', icon: ImageLinks.droplets_gray },
// 	{ key: 'speed', title: 'Avg. Speed', icon: ImageLinks.speedometer_gray },
// 	{ key: 'battery', title: 'Battery', icon: ImageLinks.battery_gray },
// 	{ key: 'temperature', title: 'Temperature', icon: ImageLinks.thermometer_gray },
// ];

const Overview = () => {
	const { entity_details, fleet_stats, app } = useContext(DashboardContext);
	const [show_select_widget_modal, set_show_select_widget_modal] = useState(false);
	const classes = useStyles();

	const get_border_color = () => {
		if (_.isEmpty(entity_details)) return;
		const online_flag = entity_details.is_online;
		const ongoing_flag = entity_details.delivery_stats.ongoing;
		const shift_flag = entity_details.shift;
		let border_color = theme.colors.lightGrey2;
		if (online_flag && (ongoing_flag == 0 || ongoing_flag === null)) border_color = theme.colors.green;
		if (ongoing_flag >= 1) border_color = theme.colors.darkYellow;
		if (!online_flag && !_.isEmpty(shift_flag)) border_color = theme.colors.red;
		if (_.isEmpty(shift_flag)) border_color = theme.colors.lightGrey2;

		return `2px solid ${border_color}`;
	};
	return (
		<div>
			<div>
				{app.entity_type == 'fleet' ? (
					<div className={classes.item_wrapper}>
						<div className='d-flex align_item_center' style={{ marginBottom: 4 }}>
							<img src={ImageLinks.truck_purple} alt='truck' />
							<Text bold style={{ fontSize: 14, marginLeft: 4 }}>
								{_.get(entity_details, 'entity_name', '')}
							</Text>
						</div>
						<Text medium style={{ fontSize: 12, color: theme.colors.gray }}>
							{_.get(entity_details, 'brand', '')} {_.get(entity_details, 'brand', '') ? '|' : ''} {_.get(entity_details, 'vehicle_type', '')}{' '}
							{_.get(entity_details, 'capacity', '') ? `| ${entity_details.capacity} m³` : ''}
						</Text>
					</div>
				) : (
					<div className={classes.item_wrapper} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
						<div className={classes.avatar_wrap} style={{ border: get_border_color() }}>
							<img
								src={_.isEmpty(entity_details?.profile_pic) ? ImageLinks.user_filled_purple : entity_details.profile_pic}
								width={_.isEmpty(entity_details?.profile_pic) ? 28 : 50}
								height={_.isEmpty(entity_details?.profile_pic) ? 28 : 50}
								style={!_.isEmpty(entity_details?.profile_pic) ? { borderRadius: 25 } : {}}
							/>
						</div>
						<div>
							<Text bold style={{ fontSize: 14 }}>
								{_.get(entity_details, 'entity_name', '')} ({_.get(entity_details, 'rider_id', '')})
							</Text>
							<Text medium style={{ fontSize: 12, color: theme.colors.gray, marginTop: 4 }}>
								{_.get(entity_details, 'vehicle_type', '')} {_.get(entity_details, 'bag_volume', '') ? `| ${entity_details.bag_volume} m³` : ''}
							</Text>
						</div>
					</div>
				)}

				<div className={clsx('d-flex', classes.item_wrapper)} style={{}}>
					{app.entity_type == 'fleet' ? (
						<>
							<div className='d-flex align_item_center' style={{ flex: 1 }}>
								<IconWrapper icon={ImageLinks.gps_gray} />
								<div>
									<Text semi style={{ fontSize: 14, marginLeft: 4 }}>
										{(_.get(fleet_stats, 'totalDistanceTravelled', '0') / 1000).toFixed(2)}
										<Text component={'span'} semi style={{ fontSize: 12 }}>
											km
										</Text>
									</Text>
									<Text semi style={{ fontSize: 10, color: theme.colors.gray }}>
										Distance Travelled
									</Text>
								</div>
							</div>
							<div style={{ flex: 1 }}>
								<Text semi style={{ fontSize: 14, marginLeft: 4 }}>
									-
									<Text component={'span'} semi style={{ fontSize: 12 }}>
										km
									</Text>
								</Text>
								<Text semi style={{ fontSize: 10, color: theme.colors.gray }}>
									Distance Remaining
								</Text>
							</div>
						</>
					) : (
						<>
							<div className='d-flex align_item_center' style={{ marginRight: 20 }}>
								<IconWrapper icon={ImageLinks.clock_gray} />
								<div>
									<Text semi style={{ fontSize: 14 }}>
										{entity_details?.shift?.time_slot_from_real
											? `${moment(entity_details.shift.time_slot_from_real).format('hh:mm A')} - ${moment(
													entity_details.shift.time_slot_to_real,
											  ).format('hh:mm A')}`
											: '-'}
									</Text>
									<Text semi style={{ fontSize: 10, color: theme.colors.gray }}>
										Shift Time
									</Text>
								</div>
							</div>
							<div>
								<Text semi style={{ fontSize: 14 }}>
									{entity_details?.shift?.hub_title || '-'}
								</Text>
								<Text semi style={{ fontSize: 10, color: theme.colors.gray }}>
									Shift Hub
								</Text>
							</div>
						</>
					)}
				</div>

				{/* <div className='d-flex' style={{ flexWrap: 'wrap' }}>
					{_.map(overview_options, (overview_item, overview_index) => (
						<div
							onClick={() => set_show_select_widget_modal(true)}
							key={`overviw_item_${overview_index}`}
							className={clsx('d-flex cursor-pointer', classes.item_wrapper)}
							style={{ width: 147, marginRight: overview_index % 2 === 0 ? 6 : 0 }}>
							<div className='d-flex align_item_center'>
								<IconWrapper icon={overview_item.icon} />
								<div style={{ marginLeft: 4 }}>
									<Text semi style={{ fontSize: 14 }}>
										120L
									</Text>
									<Text semi style={{ fontSize: 10, color: theme.colors.gray }}>
										{overview_item.title}
									</Text>
								</div>
							</div>
						</div>
					))}
				</div> */}
			</div>
			{show_select_widget_modal && <SelectOverviewWidgetModal close={() => set_show_select_widget_modal(false)} />}
		</div>
	);
};
export default Overview;
