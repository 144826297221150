import React, { useContext, useEffect } from 'react';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'resources/ImageLinks';
import { makeStyles } from '@material-ui/core';
import DashboardContext from '../context';

const useStyles = makeStyles((theme) => ({
	item_wrapper: {
		padding: 12,
		backgroundColor: `rgba(255, 255, 255, 0.60)`,
		border: `1px solid ${theme.colors.white}`,
		borderRadius: 6,
		marginTop: 12,
	},
}));

const Alert = () => {
	const classes = useStyles();
	const { fetch_alert_list, set_alert_list, alert_list, start_date_time, end_date_time, set_selected_interval_index } = useContext(DashboardContext);

	useEffect(() => {
		fetch_alert_list();
		return () => {
			set_selected_interval_index(null);
			set_alert_list([]);
		};
	}, [start_date_time, end_date_time]);

	return (
		<div>
			<div className='cursor-pointer'>
				<div className={classes.item_wrapper}>
					<div className='d-flex flex_between_center'>
						<div className='d-flex align_item_center'>
							<img src={ImageLinks.exclamation_solid_red} alt='warning' />
							<Text bold style={{ fontSize: 14, marginLeft: 4 }}>
								Alerts
							</Text>
						</div>
						<Text bold style={{ fontSize: 14 }}>
							{alert_list.length}
						</Text>
					</div>
					<Text medium style={{ fontSize: 12, color: theme.colors.gray, marginTop: 4 }}>
						{alert_list.length > 0 ? 'Click on alert to view details' : 'No alerts have been flagged'}
					</Text>
				</div>
			</div>
		</div>
	);
};
export default Alert;
