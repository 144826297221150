import utils from './utils';
import constant from './constant';

const api_requests = {
	get_fleet_single_vehicle: (id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/vehicles/${id}`,
			version: 2,
			base_url: constant.BASE_URL.PIDGE,
		});
	},
	get_fleet_single_rider: (id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/riders/${id}/info`,
			version: 2,
			base_url: constant.BASE_URL.PIDGE,
		});
	},

	get_trace_stats: (id, params) => {
		return utils.request({
			method: 'GET',
			url: `trace/api/trace/${id}/stats`,
			params,
		});
	},

	get_trace_location: (id, params) => {
		return utils.request({
			method: 'GET',
			url: `trace/api/trace/${id}/locations`,
			params,
		});
	},
	get_alert_list: (id, params) => {
		return utils.request({
			method: 'GET',
			url: `trace/api/trace/${id}/alerts`,
			params,
		});
	},
	get_routes_list: (id, params) => {
		return utils.request({
			method: 'GET',
			url: `trace/api/trace/${id}/route`,
			params,
		});
	},
	create_routes: (data) => {
		return utils.request({
			method: 'POST',
			url: `trace/api/trace/route`,
			data,
		});
	},
	delete_route: (entity_id, route_id) => {
		return utils.request({
			method: 'DELETE',
			url: `trace/api/trace/${entity_id}/route/${route_id}`,
		});
	},
	get_route_item_list: (id, route_id, params) => {
		return utils.request({
			method: 'GET',
			url: `trace/api/trace/${id}/route/${route_id}`,
			params,
		});
	},
	get_common_configs: (business_id) => {
		return utils.request({
			base_url: constant.BASE_URL.PIDGE,
			method: 'GET',
			url: `user/bop/configs`,
			version: 2,
		});
	},
	get_route_assignments: (entity_id, params) => {
		return utils.request({
			base_url: constant.BASE_URL.PIDGE,
			method: 'GET',
			url: `user/bop/rider/vehicles/reservations/${entity_id}`,
			params,
			version: 2,
		});
	},
	get_rider_tasks: (rider_id, params = {}) => {
		return utils.request({
			base_url: constant.BASE_URL.PIDGE,
			method: 'get',
			url: `user/bop/rider/${rider_id}/tasks`,
			params: {
				...params,
			},
		});
	},
	get_rider_completed_task_list: (rider_id, date) => {
		return utils.request({
			base_url: constant.BASE_URL.PIDGE,
			method: 'GET',
			url: `user/bop/riders/${rider_id}/tasks/completed/${date}`,
			version: 2,
		});
	},
};

export default api_requests;
