import React from 'react';
import Timeline from 'views/Dashboard/components/Timeline';

import useDashboard from './useDashboard';
import DashboardContext from './context';
import TraceMap from './components/TraceMap';
import LeftWidgets from './components/LeftWidgets';
import AlertList from './components/AlertList';
import RouteItemList from './components/RouteItemList';
// import RightWidget from './components/RightWidget';
import ActiveTaskList from './components/ActiveTaskList';

const Dashboard = () => {
	const value = useDashboard();

	return (
		<DashboardContext.Provider value={value}>
			<div style={{ flex: 1 }}>
				<TraceMap />
				<LeftWidgets />
				{/* <RightWidget /> */}
				<AlertList />
				<Timeline />
				<RouteItemList />
				<ActiveTaskList />
			</div>
		</DashboardContext.Provider>
	);
};

export default Dashboard;
