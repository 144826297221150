import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import theme from 'resources/theme';

const useStyles = makeStyles(() => ({
	container: {
		borderRadius: 50,
	},
	circle: {
		padding: 10,
		borderRadius: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const OutlinedButton = ({ children, circle, className, style = {}, disabled = false, ...rest }) => {
	const classes = useStyles();

	return (
		<Button
			variant='outlined'
			color='primary'
			disabled={disabled}
			style={{
				textTransform: 'initial',
				minWidth: 0,
				letterSpacing: 'unset',
				borderColor: disabled ? theme.colors.lightGrey : theme.colors.primary,
				...style,
			}}
			className={clsx(classes.container, circle && classes.circle, className)}
			{...rest}>
			{children}
		</Button>
	);
};

export default OutlinedButton;
