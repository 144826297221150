const ImageLinks = {
	bar_purple: require('../assets/icons/bar_purple.svg').default,
	info_circle_purple: require('../assets/icons/info_circle_purple.svg').default,
	route_purple: require('../assets/icons/route_purple.svg').default,
	share_purple: require('../assets/icons/share_purple.svg').default,
	truck_purple: require('../assets/icons/truck_purple.svg').default,
	pidge_logo: require('../assets/icons/pidge_logo.svg').default,
	gps_gray: require('../assets/icons/gps_gray.svg').default,
	droplets_gray: require('../assets/icons/droplets_gray.svg').default,
	speedometer_gray: require('../assets/icons/speedometer_gray.svg').default,
	battery_gray: require('../assets/icons/battery_gray.svg').default,
	thermometer_gray: require('../assets/icons/thermometer_gray.svg').default,
	box_gray: require('../assets/icons/box_gray.svg').default,
	filled_checkbox: require('../assets/icons/filled_checkbox.svg').default,
	empty_checkbox: require('../assets/icons/empty_checkbox.svg').default,
	disabled_checkbox: require('../assets/icons/disabled_checkbox.svg').default,
	chevron_left_solid_purple: require('../assets/icons/chevron_left_solid_purple.svg').default,
	empty_rider_routes: require('../assets/empty_rider_routes.svg').default,
	refresh_purple: require('../assets/icons/refresh_purple.svg').default,
	rider_light_purple: require('../assets/icons/rider_light_purple.svg').default,
	box_light_purple: require('../assets/icons/box_light_purple.svg').default,
	download_purple: require('../assets/icons/download_purple.svg').default,
	upload_light_purple: require('../assets/icons/upload_light_purple.svg').default,
	delete_red: require('../assets/icons/delete_red.svg').default,
	marker_arrow_green: require('../assets/icons/marker_arrow_green.svg').default,
	marker_arrow_purple: require('../assets/icons/marker_arrow_purple.svg').default,
	exclamation_solid_red: require('../assets/icons/exclamation_solid_red.svg').default,
	cross_circle_solid_purple: require('../assets/icons/cross_circle_solid_purple.svg').default,
	chevron_down_hollow_purple: require('../assets/icons/chevron_down_hollow_purple.svg').default,
	dotted_double_circle_black: require('../assets/icons/dotted_double_circle_black.svg').default,
	rupee_circle_light_purple: require('../assets/icons/rupee_circle_light_purple.svg').default,
	play_solid_purple: require('../assets/icons/play_solid_purple.svg').default,
	play_solid_gray: require('../assets/icons/play_solid_gray.svg').default,
	pause_solid_purple: require('../assets/icons/pause_solid_purple.svg').default,
	reload_purple: require('../assets/icons/reload_purple.svg').default,
	route_coming_soon: require('../assets/icons/route_coming_soon.svg').default,
	truck_purple_circle_ripple: require('../assets/icons/truck_purple_circle_ripple.svg').default,
	uat_404: require('../assets/uat_404.png'),
	empty_map: require('../assets/empty_map.svg').default,
	empty_clock: require('../assets/empty_clock.svg').default,
	arrow_right_white: require('../assets/icons/arrow_right_white.svg').default,
	speedometer_purple: require('../assets/icons/speedometer_purple.svg').default,
	exclamation_red_filled_marker: require('../assets/icons/exclamation_red_filled_marker.svg').default,
	arrow_right_purple: require('../assets/icons/arrow_right_purple.svg').default,
	empty_routes: require('../assets/empty_routes.svg').default,
	download_circle_purple: require('../assets/icons/download_circle_purple.svg').default,
	upload_white: require('../assets/icons/upload_white.svg').default,
	delete_red_ripple: require('../assets/icons/delete_red_ripple.svg').default,
	cross_black: require('../assets/icons/cross_black.svg').default,
	chevron_down_solid_purple: require('../assets/icons/chevron_down_solid_purple.svg').default,
	cross_circle_light_purple: require('../assets/icons/cross_circle_light_purple.svg').default,
	upload_purple: require('../assets/icons/upload_purple.svg').default,
	marker_purple: require('../assets/icons/marker_purple.svg').default,
	chevron_up_solid_green: require('../assets/icons/chevron_up_solid_green.svg').default,
	calendar_filled_purple: require('../assets/icons/calendar_filled_purple.svg').default,
	calendar_filled_dark_purple: require('../assets/icons/calendar_filled_dark_purple.svg').default,
	tick_circle_green_solid: require('../assets/icons/tick_circle_green_solid.svg').default,
	cross_red: require('../assets/icons/cross_red.svg').default,
	folder_light_purple: require('../assets/icons/folder_light_purple.svg').default,
	cricle_with_dashed_light_purple: require('../assets/icons/cricle_with_dashed_light_purple.svg').default,
	empty_assignment: require('../assets/empty_assignment.svg').default,
	rider_purple: require('../assets/rider_purple.svg').default,
	clock_gray: require('../assets/clock.svg').default,
	user_filled_purple: require('../assets/user_filled_purple.svg').default,
	arrow_right_black: require('../assets/icons/arrow_right_black.svg').default,
	rider_purple_circle: require('../assets/icons/rider_purple_circle.svg').default,
	tick_circle_solid_gray: require('../assets/icons/tick_circle_solid_gray.svg').default,
};

export default ImageLinks;
