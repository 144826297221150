import React from 'react';
import _ from 'lodash';

const Upload = ({ style = {}, accepted_extensions, add_image, children }) => {
	const inputRef = React.useRef();

	const handleChange = (e) => {
		let files = _.get(e, 'target.files') || e;

		let extension = '';
		_.each(files, (file) => {
			let reader = new FileReader();
			reader.onload = (event) => {
				extension = _.split(file.name, '.');
				extension = extension[extension.length - 1];
				let fileInfo = {
					name: file.name,
					type: file.type,
					size: Math.round(file.size / 1000),
					base64: reader.result,
					file: file,
					extension: extension,
				};

				if (_.includes(accepted_extensions, fileInfo.extension)) {
					if (_.isFunction(add_image)) add_image(fileInfo, inputRef);
				} else {
					alert('File should be of jpg, jpeg or png format');
				}
			};
			reader.readAsDataURL(file);
		});
	};

	const open_file_selection = () => {
		inputRef.current.value = null;
		inputRef.current.click();
	};

	return (
		<div
			style={{
				cursor: 'pointer',
				...style,
			}}
			onClick={open_file_selection}>
			{children}
			<input type='file' ref={inputRef} onChange={handleChange} accept={accepted_extensions} style={{ display: 'none' }} />
		</div>
	);
};

export default Upload;
