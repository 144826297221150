import { createTheme } from '@material-ui/core';

const basicTheme = createTheme({
	palette: {
		primary: {
			main: '#492897',
		},
		secondary: {
			main: '#492897',
		},
		white: {
			main: '#ffffff',
		},
		success: {
			main: '#0F8560',
		},
		error: {
			main: '#BC0000',
		},
	},
	overrides: {
		RaAutocompleteSuggestionList: {
			suggestionsContainer: {
				zIndex: 9000,
			},
		},
	},
});

const theme = {
	colors: {
		primary: '#492897',
		light_white: '#FDFCFF',
		light_purple: '#F2EEF8',
		white: '#FFFFFF',
		black: '#000000',
		gray: '#666666',
		light_primary: '#E4DCF3',
		light_pink: '#7953D2',
		light_pink2: '#CABDE9',
		light_gray: '#E5E5E5',
		light_gray2: '#C4C4C4',
		red: '#BC0000',
		light_pink3: '#F3EFFB',
		light_pink4: '#9980DB',
		light_red: '#FFEAEC',
		lightGrey2: '#C4C4C4',
		green: '#0F8560',
		darkYellow: '#F2C16B',
		lightGrey6: '#707070',
		darkGrey7: '#1E1E1E',
		darkGrey8: '#666',
	},
	...basicTheme,
};

export default theme;
