import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import React from 'react';
import ImageLinks from 'resources/ImageLinks';

const NotFound = () => {
	return (
		<div style={{ flex: 1, height: '100%', display: 'flex' }}>
			<img src={ImageLinks.empty_map} alt='map' />
			<div className='d-flex flex_center_content' style={{ flexDirection: 'column', marginLeft: 100 }}>
				<img src={ImageLinks.empty_clock} alt='clock' />
				<Text bold style={{ fontSize: 32, marginTop: 33 }}>
					Something Went Wrong
				</Text>
				<ContainedButton style={{ marginTop: 20, borderRadius: 30 }}>
					<Text semi style={{ fontSize: 16, marginRight: 6 }}>
						Back to Pidge
					</Text>
					<img src={ImageLinks.arrow_right_white} alt='right' />
				</ContainedButton>
			</div>
		</div>
	);
};

export default NotFound;
