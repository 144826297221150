import React, { useContext } from 'react';
import _ from 'lodash';
import Text from 'modules/Text';
import theme from 'resources/theme';
import { makeStyles } from '@material-ui/core';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
import DashboardContext from '../context';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const useStyles = makeStyles((theme) => ({
	item_wrapper: {
		padding: 12,
		backgroundColor: `rgba(255, 255, 255, 0.60)`,
		border: `1px solid ${theme.colors.white}`,
		borderRadius: 6,
		marginTop: 12,
	},
	half_donut_text_container: {
		position: 'absolute',
		bottom: 20,
		left: '35%',
	},
}));

const Stats = () => {
	const { fleet_stats } = useContext(DashboardContext);
	const classes = useStyles();

	// const colors = ['#26A69A', '#4285F4', '#C0CA33', '#FF9800', '#BC0000'];
	// const data = {
	// 	labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple'],
	// 	datasets: [
	// 		{
	// 			data: [12, 19, 3, 5, 2],
	// 			backgroundColor: colors,
	// 			borderWidth: 1,
	// 		},
	// 	],
	// };

	// const options = {
	// 	plugins: {
	// 		legend: {
	// 			display: false,
	// 		},
	// 		tooltip: {
	// 			enabled: true,
	// 		},
	// 		datalabels: {
	// 			display: false,
	// 		},
	// 	},
	// 	maintainAspectRatio: true,
	// };

	const hour_data = [
		{
			label: 'Moving Hours',
			value: fleet_stats.totalMovingTime ? fleet_stats.totalMovingTime : 0,
			bg_color: '#40A044',
		},

		{
			label: 'Idle Hours',
			value: fleet_stats.totalIdleTime ? fleet_stats.totalIdleTime : 0,
			bg_color: '#4285F4',
		},
		{
			label: 'No Data Hours',
			value: fleet_stats.noDataTime ? fleet_stats.noDataTime : 0,
			bg_color: '#A9DDD8',
		},
	];
	const donut_data = {
		labels: _.map(hour_data, (item) => item.label),
		datasets: [
			{
				data: _.map(hour_data, (item) => item.value),
				backgroundColor: _.map(hour_data, (item) => item.bg_color),
				borderWidth: 1,
			},
		],
	};

	const donut_options = {
		hover: { mode: null },
		elements: {
			arc: {
				borderJoinStyle: 'bevel',
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
			datalabels: {
				display: false,
			},
		},
		maintainAspectRatio: false,
		rotation: -90,
		circumference: 180,
		cutout: 50,
	};

	return (
		<div>
			<div>
				{/* <div className={classes.item_wrapper}>
					<Text semi style={{ fontSize: 14 }}>
						Event Count
					</Text>
					<div style={{ border: `1px solid ${theme.colors.light_gray}`, marginTop: 8, marginBottom: 8 }} />

					<div className='d-flex align_item_center'>
						<div style={{ width: 100, height: 100 }}>
							<Pie data={data} options={options} />
						</div>
						<div style={{ flex: 1, marginLeft: 30 }}>
							{_.map(['#26A69A', '#4285F4', '#C0CA33', '#FF9800', '#BC0000'], (color_item, color_index) => (
								<div key={`piet_data${color_index}`} className='d-flex flex_between_center' style={{ marginTop: color_index === 0 ? 0 : 12 }}>
									<div className='d-flex align_item_center'>
										<div style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: color_item }} />
										<Text semi style={{ fontSize: 12, marginLeft: 8 }}>
											Ignition ON
										</Text>
									</div>
									<Text semi style={{ fontSize: 12 }}>
										11
									</Text>
								</div>
							))}
						</div>
					</div>
				</div> */}

				<div className={classes.item_wrapper}>
					<Text semi style={{ fontSize: 14 }}>
						Vehicle Movement
					</Text>
					<div style={{ border: `1px solid ${theme.colors.light_gray}`, marginTop: 8, marginBottom: 8 }} />

					<div className='d-flex align_item_center' style={{ flexDirection: 'column' }}>
						<div style={{ width: 210, position: 'relative' }}>
							<Doughnut data={donut_data} options={donut_options} />
							<div className={classes.half_donut_text_container}>
								<Text bold className='text-align-center' style={{ fontSize: 20 }}>
									{/* {Math.round(_.get(tracking_data, 'click_percentage', null))}% */}
									{_.reduce(
										hour_data,
										(sum, item) => {
											return sum + parseFloat(item.value);
										},
										0,
									)}
								</Text>
								<Text medium className='text-align-center' style={{ fontSize: 12, color: theme.colors.gray }}>
									Total Hours
								</Text>
							</div>
						</div>

						<div className='d-flex flex_between_center' style={{ flex: 1 }}>
							{_.map(hour_data, (hour_item, hour_index) => (
								<div key={`piet_data${hour_index}`} className='d-flex' style={{ marginRight: 5 }}>
									<div style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: hour_item.bg_color, marginTop: 5 }} />
									<div style={{ marginLeft: 8 }}>
										<Text semi style={{ fontSize: 12, color: theme.colors.gray }}>
											{hour_item.label}
										</Text>
										<Text semi style={{ fontSize: 12 }}>
											{hour_item.value}hrs
										</Text>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Stats;
