import { makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import DashboardContext from '../context';
import _ from 'lodash';
import moment from 'moment';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'resources/ImageLinks';
import OrderCard from './OrderCard';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		right: 20,
		top: 20,
		zIndex: 1000,
		width: 330,
		borderRadius: 6,
		backgroundColor: theme.colors.white,
		padding: 16,
		maxHeight: 700,
		overflowY: 'auto',
	},
	route_item_wrap: {
		padding: 8,
		borderRadius: 4,
		backgroundColor: theme.colors.white,
		marginBottom: 8,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		boxShadow: `0px 2px 4px 0px rgba(196, 196, 196, 0.30)`,
	},
	number_wrap: {
		width: 16,
		height: 16,
		borderRadius: 8,
		backgroundColor: theme.colors.light_purple,
		marginRight: 4,
	},
}));

const today_date = moment().format('YYYY-MM-DD');

const RouteItemList = () => {
	const {
		selected_route_list_item_index,
		route_item_list,
		set_center_position,
		set_zoom,
		handle_close_route_list,
		selected_route_order_item,
		set_selected_route_order_item,
	} = useContext(DashboardContext);
	const classes = useStyles();

	if (_.isEmpty(route_item_list)) return null;
	if (!_.isEmpty(selected_route_order_item))
		return (
			<OrderCard
				data={selected_route_order_item}
				handle_close={() => {
					set_zoom(10);
					set_selected_route_order_item(null);
				}}
			/>
		);
	return (
		<div className={classes.container}>
			{selected_route_list_item_index >= 0 && (
				<>
					<div className='flex_between_center' style={{ marginBottom: 14 }}>
						<Text semi style={{ fontSize: 14 }}>
							Task Sequence
						</Text>
						<img
							src={ImageLinks.cross_circle_solid_purple}
							width={16}
							height={16}
							style={{ cursor: 'pointer' }}
							onClick={handle_close_route_list}
						/>
					</div>
					{_.map(route_item_list.routes, (item, index) => {
						return (
							<div key={`route_detail_item_${index}`} className='d-flex align_item_center'>
								{item.sequence && (
									<div className={clsx(classes.number_wrap, 'flex_center_content')}>
										<Text bold style={{ fontSize: 12, color: theme.colors.primary }}>
											{item.sequence}
										</Text>
									</div>
								)}
								<div
									className={classes.route_item_wrap}
									style={{ width: item.sequence ? 275 : 300 }}
									onClick={() => {
										set_center_position([item.latitude, item.longitude]);
										set_zoom(18);
										set_selected_route_order_item(item);
									}}>
									<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
										{item.ref_id}
									</Text>
									<div>
										<Text semi style={{ fontSize: 12 }}>
											{_.isEmpty(item.eta) ? '-' : moment(item.eta).format('hh:mm A')}
										</Text>
										<Text semi style={{ fontSize: 10, color: theme.colors.gray }}>
											~Time
										</Text>
									</div>
									<div className='d-flex align_item_center'>
										<Text semi style={{ fontSize: 10, color: theme.colors.light_gray2 }}>
											{item.point_type === 1 ? 'Pickup' : 'Drop'}
										</Text>
										<img
											src={item.point_type === 1 ? ImageLinks.chevron_up_solid_green : ImageLinks.chevron_down_solid_purple}
											style={{ marginLeft: 8 }}
										/>
									</div>
								</div>
							</div>
						);
					})}
				</>
			)}
		</div>
	);
};

export default RouteItemList;
