/**
 * constant file for whole app
 */
import _ from 'lodash';

const env = {
	STAGING: 'Staging',
	PRODUCTION: 'Production',
};

let ENVIRONMENT = env.STAGING;

if (process.env.REACT_APP_ENV === 'prod') {
	ENVIRONMENT = env.PRODUCTION;
}
const TRIP_STATUS = {
	DELIVERED: {
		id: 190,
		text: 'Delivered',
	},
	SCHEDULED: {
		id: 30,
		text: 'Scheduled',
	},
	CANCELLED: {
		id: 0,
		text: 'Cancelled',
	},
	ALLOCATED: {
		id: 80,
		text: 'Allocated',
	},
	RIDER_CONFIRMED: {
		id: 90,
		text: 'Rider Confirmed',
	},
	STARTED_FOR_PICKUP: {
		id: 100,
		text: 'Started for pickup',
	},
	REACHED_PICKUP: {
		id: 120,
		text: 'Reached Pickup',
	},
	PACKAGE_SCANNED: {
		id: 130,
		text: 'Package Scanned',
	},
	STARTED_FOR_DELIVERY: {
		id: 140,
		text: 'Started for delivery',
	},
	DELIVERY_START: {
		id: 150,
		text: 'Delivery Start',
	},
	REACHED_DROP_LOCATION: {
		id: 170,
		text: 'Reached Drop Location',
	},
	SCANNED_DROP: {
		id: 180,
		text: 'Scanned at drop',
	},
	POD_UPLOADED: {
		id: 185,
		text: 'POD Uploaded',
	},
	FAILED_DELIVERY: {
		id: 5,
		text: 'Failed Delivery',
	},
};
const TASK_TYPES = {
	PICKUP: {
		id: 10,
	},
	DROP: {
		id: 20,
	},
};
const constant = {
	env,
	ENVIRONMENT,
	BASE_URL: {
		DEFAULT: 'API_TRACE_URL',
		STORE: 'API_STORE_URL',
		PIDGE: 'API_URL',
	},
	API_URL: '',
	API_STORE_URL: '',
	API_TRACE_URL: '',
	// API_URL: {
	// 	DEV: 'https://uat-api.pidge.in/',
	// 	PROD: 'https://dev-release-v1.pidge.in/',
	// },
	// API_STORE_URL: {
	// 	DEV: 'https://uat-store.pidge.in/',
	// 	PROD: 'https://api.pidge.in/',
	// },
	// API_TRACE_URL: {
	// 	DEV: 'https://uat-trace.pidge.in/',
	// 	PROD: 'https://trace-api.pidge.in/',
	// },
	REDUX_STATE: {
		IDLE: 'idle',
		SUCCEEDED: 'succeeded',
		FAILED: 'failed',
	},
	INDIA_MAP_CENTER: { lat: Number(20.5937), lng: Number(78.9629) },
	REDUX_STATE: {
		IDLE: 'idle',
		SUCCEEDED: 'succeeded',
		FAILED: 'failed',
	},
	BTN_STATE: {
		DEFAULT: 'default',
		SUCCESS: 'success',
		FAILED: 'failed',
		LOADING: 'loading',
	},
	BOP_URL: '',
	TRIP_STATUS,
	TRIP_STATUS_TEXT: _.reduce(
		TRIP_STATUS,
		(map, status) => {
			map[status.id] = status.text;
			return map;
		},
		{},
	),
	TASK_TYPES,
	SENTRY_DSN: 'https://324092cb12f87618dc01bdf7c67447dd@o274231.ingest.us.sentry.io/4506903353622528',
	// BOP_URL: ENVIRONMENT === env.STAGING ? 'https://business-dev.pidge.in/' : 'https://dashboard.pidge.in/',
};

export default constant;
