import _ from 'lodash';
import { LOGIN_DETAILS_RECEIVED, UPDATE_SELECTED_WIDGET, URL_BREAK, SHOW_LOADER, HIDE_LOADER } from 'actions/actionConstants';
import constant from 'resources/constant';

const initialState = {
	entity_id: null,
	token: null,
	owner_id: null,
	owner_type: null,
	is_loading: false,
	selected_widget: ['overview'],
	status: constant.REDUX_STATE.IDLE,
	app_configs: {},
	start_date_time: null,
	end_date_time: null,
};

const app = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_DETAILS_RECEIVED: {
			return {
				...state,
				entity_id: action.data.entity_id,
				entity_type: action.data.entity_type,
				token: action.data.token,
				owner_id: action.data.owner_id,
				owner_type: action.data.owner_type,
				status: constant.REDUX_STATE.SUCCEEDED,
				app_configs: action.data.app_configs,
				start_date_time: action.data.start_date_time,
				end_date_time: action.data.end_date_time,
			};
		}

		case UPDATE_SELECTED_WIDGET: {
			const copy = _.cloneDeep(state.selected_widget);
			const index = _.indexOf(copy, action.data);
			if (index >= 0) copy.splice(index, 1);
			else copy.push(action.data);

			return {
				...state,
				selected_widget: copy,
			};
		}

		case URL_BREAK:
			return { ...state, status: constant.REDUX_STATE.FAILED };

		case SHOW_LOADER:
			return { ...state, is_loading: true };

		case HIDE_LOADER:
			return { ...state, is_loading: false };

		default:
			return state;
	}
};

export default app;
