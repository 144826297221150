import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Text from './Text';

const useStyles = makeStyles((theme) => ({
	time_box: {
		border: '1px solid ' + theme.colors.primary,
		padding: '10px 17px',
		borderRadius: 4,
	},
	time_wrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: 5,
	},
	active_am_pm: {
		backgroundColor: theme.colors.primary,
		color: theme.colors.white,
		border: '1px solid ' + theme.colors.primary,
		padding: '4px 7px',
	},
	inactive_am_pm: {
		backgroundColor: theme.colors.white,
		color: theme.colors.light_primary,
		border: '1px solid ' + theme.colors.light_primary,
		padding: '4px 7px',
	},

	am_pm_text: { fontSize: 8 },
}));

function TimeInput({ handleChange, value = null, disabled = false }) {
	const classes = useStyles();
	const [selected_hour, set_selected_hour] = useState(
		value ? (moment(value, 'h:mm').hour() > 12 ? moment(value, 'h:mm').hour() - 12 : moment(value, 'h:mm').hour()) : 12,
	);
	const [hour_menu_open, set_hour_menu_open] = useState(false);
	const hour_ref = useRef();
	const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	const [selected_minute, set_selected_minute] = useState(value ? moment(value, 'h:mm').minute() : 0);
	const [minute_menu_open, set_minute_menu_open] = useState(false);
	const minute_ref = useRef();
	const minutes = [0, 15, 30, 45];
	const [am_pm, set_am_pm] = useState(value ? (moment(value, 'hh:mm').hour() >= 12 ? 'pm' : 'am') : 'am');

	useEffect(() => {
		handleChange(moment(`${selected_hour}:${selected_minute} ${am_pm}`, 'hh:mm A').format('HH:mm'));
	}, [am_pm, selected_hour, selected_minute]);

	useEffect(() => {
		set_selected_hour(value ? (moment(value, 'hh:mm').hour() > 12 ? moment(value, 'hh:mm').hour() - 12 : moment(value, 'h:mm').hour()) : 12);
		set_selected_minute(value ? moment(value, 'hh:mm').minute() : 0);
		set_am_pm(value ? (moment(value, 'hh:mm').hour() >= 12 ? 'pm' : 'am') : 'am');
	}, [value]);

	return (
		<div>
			<div className={classes.time_wrapper}>
				<div
					ref={hour_ref}
					onClick={() => {
						if (!disabled) set_hour_menu_open((prev) => !prev);
					}}
					className={classes.time_box}>
					<Text semi style={{ fontSize: 14 }}>
						{selected_hour == 0 ? 12 : selected_hour}
					</Text>
				</div>
				<div>:</div>
				<div
					ref={minute_ref}
					onClick={() => {
						if (!disabled) set_minute_menu_open((prev) => !prev);
					}}
					className={classes.time_box}>
					<Text semi style={{ fontSize: 14 }}>
						{selected_minute ? selected_minute : '00'}
					</Text>
				</div>
				<div style={{ marginLeft: 10 }}>
					<div
						className={am_pm == 'am' ? classes.active_am_pm : classes.inactive_am_pm}
						onClick={() => {
							if (!disabled) set_am_pm('am');
						}}
						style={{ borderRadius: '4px 4px 0 0' }}>
						<Text semi className={classes.am_pm_text}>
							AM
						</Text>
					</div>
					<div
						className={am_pm == 'pm' ? classes.active_am_pm : classes.inactive_am_pm}
						onClick={() => {
							if (!disabled) set_am_pm('pm');
						}}
						style={{ borderRadius: '0 0 4px 4px' }}>
						<Text semi className={classes.am_pm_text}>
							PM
						</Text>
					</div>
				</div>
			</div>
			{hour_menu_open && (
				<Menu
					anchorEl={hour_ref.current}
					open={hour_menu_open}
					PaperProps={{
						style: {
							borderRadius: 10,
							marginTop: 20,
							maxHeight: 400,
						},
					}}
					onClose={() => set_hour_menu_open(false)}>
					{_.map(hours, (hour, index) => {
						return (
							<MenuItem
								sx={{ width: 100 }}
								value={hour}
								onClick={(e) => {
									set_selected_hour(e.target.value);
									set_hour_menu_open(false);
								}}
								key={`hour_menu_${index}`}>
								{hour}
							</MenuItem>
						);
					})}
				</Menu>
			)}
			{minute_menu_open && (
				<Menu
					anchorEl={minute_ref.current}
					open={minute_menu_open}
					PaperProps={{
						style: {
							borderRadius: 10,
							marginTop: 50,
							maxHeight: 400,
						},
					}}
					onClose={() => set_minute_menu_open(false)}>
					{_.map(minutes, (minute, index) => {
						return (
							<MenuItem
								sx={{ width: 100 }}
								value={minute}
								onClick={(e) => {
									set_selected_minute(e.target.value);
									set_minute_menu_open(false);
								}}
								key={`minute_menu_${index}`}>
								{minute}
							</MenuItem>
						);
					})}
				</Menu>
			)}
		</div>
	);
}

export default TimeInput;
