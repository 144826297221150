import React, { useEffect } from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import qs from 'qs';
import Modal from 'react-modal';
import theme from 'resources/theme';
import { login_details_received, url_break } from 'actions/app';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import Routes from 'resources/Routes';
import _ from 'lodash';
import store from 'store';
import browserHistory from 'browserHistory';
import { MuiThemeProvider } from '@material-ui/core';
import Loader from 'modules/Loader';
import ErrorBoundary from 'modules/ErrorBoundary';
import Dashboard from 'views/Dashboard/Dashboard';
import WidgetSidebar from 'views/WidgetSidebar/WidgetSidebar';
import utils from 'resources/utils';
import NotFound from 'views/Notfound/Notfound';
import constant from 'resources/constant';
import api_requests from 'resources/api_requests';
import moment from 'moment';
import * as Sentry from '@sentry/react';

Modal.setAppElement('#root');
class App extends React.Component {
	render() {
		return (
			<Sentry.ErrorBoundary fallback={<h1>Something went wrong.</h1>}>
				<MuiThemeProvider theme={theme}>
					<ToastContainer />
					<Provider store={store}>
						<Router history={browserHistory}>
							<Switch>
								<Route path='/' component={PrimaryComponent} />
							</Switch>
						</Router>
					</Provider>
				</MuiThemeProvider>
			</Sentry.ErrorBoundary>
		);
	}
}

const PrimaryComponent = ({ history }) => {
	const { app } = useSelector((state) => state, shallowEqual);
	const query_object = qs.parse(history.location.search.slice(1));
	const dispatch = useDispatch();

	useEffect(() => {
		if (
			_.isEmpty(query_object) ||
			_.isEmpty(query_object.entity_id) ||
			_.isEmpty(query_object.token) ||
			_.isEmpty(query_object.owner_id) ||
			_.isEmpty(query_object.owner_type) ||
			_.isEmpty(query_object.entity_type)
		) {
			dispatch(url_break());
			return;
		}
		get_app_configs();
	}, []);

	const get_app_configs = async () => {
		if (!_.isEmpty(query_object.token)) {
			utils.setRequestAuthHeader(query_object.token);
			utils.setRequestAuthHeaderBusinessId(query_object.owner_id);
			utils.setRequestOwnerId(query_object.owner_type);
			const app_configs = (await api_requests.get_common_configs(query_object.owner_id)).data;
			dispatch(
				login_details_received({
					entity_type: query_object.entity_type,
					entity_id: query_object.entity_id,
					token: query_object.token,
					owner_id: query_object.owner_id,
					owner_type: query_object.owner_type,
					app_configs,
					start_date_time: query_object.start_date_time ? query_object.start_date_time : moment().startOf('day').toISOString(),
					end_date_time: query_object.end_date_time ? query_object.end_date_time : moment().add(1, 'day').startOf('day').toISOString(),
				}),
			);
		}
	};
	if (app.status === constant.REDUX_STATE.IDLE) {
		return null;
	}

	if (app.status === constant.REDUX_STATE.FAILED) {
		return (
			<Switch>
				<Route path='/' exact={true} component={NotFound} />
				<Redirect to='/' />
			</Switch>
		);
	}
	return <MainComponent />;
};

const MainComponent = ({ history }) => {
	const { app } = useSelector((state) => state, shallowEqual);

	return (
		<div style={{ flex: 1, display: 'flex', backgroundColor: theme.colors.sideNavPrimary, height: '100%' }}>
			<Loader visible={app.is_loading} />
			<div className='d-flex' style={{ width: 120 }}>
				<WidgetSidebar />
			</div>
			<div
				style={{
					flex: 1,
					height: '100%',
					position: 'relative',
					background: '#FAFAFA',
					borderTopLeftRadius: 30,
					overflowY: 'auto',
					display: 'flex',
					flexDirection: 'column',
				}}>
				<Switch>
					{_.map(
						[
							{
								path: Routes.DASHBOARD.path,
								exact: Routes.DASHBOARD.exact,
								component: Dashboard,
							},
						],
						(route, idx) => {
							return <Route key={idx} path={route.path} exact={route.exact} component={route.component} />;
						},
					)}

					<Redirect to='/' />
				</Switch>
			</div>
		</div>
	);
};

export default App;
