import React, { useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ImageLinks from 'resources/ImageLinks';
import theme from 'resources/theme';
import Text from '../../../modules/Text';
import moment from 'moment';
import TimeInput from '../../../modules/TimeInput';
import ContainedButton from '../../../modules/ContainedButton';
import _ from 'lodash';
import DashboardContext from '../context';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: 15,
		width: 490,
		borderRadius: 12,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 0px 22.4px 0px rgba(102, 102, 102, 0.20)`,
		position: 'absolute',
	},
	right_wrap: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		flex: 0.37,
		alignItems: 'flex-end',
	},
	time_label: { fontSize: 12, color: theme.colors.gray, marginBottom: 6 },
}));

const DateTimeRangePicker = ({ close }) => {
	const { start_date_time, end_date_time, set_show_date_range_modal, set_selected_date, handle_date_filter, selected_date_ref } =
		useContext(DashboardContext);

	const [start_date, set_start_date] = useState(new Date());
	const [end_date, set_end_date] = useState(new Date());
	const [start_time, set_start_time] = useState('');
	const [end_time, set_end_time] = useState('');
	const [max_date, set_max_date] = useState(new Date());
	const classes = useStyles();

	useEffect(() => {
		if (start_date_time && end_date_time) {
			const _start_date = moment(start_date_time).toDate();
			const _end_date = moment(end_date_time).toDate();
			const _start_time = moment(start_date_time).format('HH:mm');
			const _end_time = moment(end_date_time).format('HH:mm');

			set_start_date(_start_date);
			set_end_date(_end_date);
			set_start_time(_start_time);
			set_end_time(_end_time);
		}
	}, [start_date_time, end_date_time]);

	const handle_date_change = (dates) => {
		const [start, end] = dates;
		set_start_date(start);
		set_end_date(end);
		set_start_time('00:00');
		set_end_time('23:45');
		let max;
		if (end) max = new Date();
		else max = moment(start).add(6, 'days').toDate();

		set_max_date(max);
	};

	const handle_range_change = () => {
		const _start = `${moment(start_date).format('YYYY-MM-DD')} ${start_time}`;
		const _end = `${moment(end_date).format('YYYY-MM-DD')} ${end_time}`;
		set_show_date_range_modal(false);
		set_selected_date(moment(start_date).format('YYYY-MM-DD'));
		selected_date_ref.current = moment(start_date).format('YYYY-MM-DD');
		handle_date_filter(_start, _end);
	};
	// const years = range(1990, getYear(new Date()) + 1, 1);
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	const btn_disabled = useMemo(() => {
		let value = true;
		if (!_.isEmpty(start_time) && !_.isEmpty(end_time) && start_date && end_date) value = false;
		return value;
	}, [start_date, end_date, start_time, end_time]);

	return (
		<div className={classes.wrapper}>
			<div className='flex_between_center'>
				<Text bold style={{ fontSize: 14, color: theme.colors.gray }}>
					Select Date & Time
				</Text>
				<img className='cursor-pointer' src={ImageLinks.cross_circle_light_purple} width={18} height={18} onClick={close} />
			</div>

			<div style={{ display: 'flex', marginTop: 12 }}>
				<div style={{ flex: 0.63 }}>
					<DatePicker
						calendarClassName='custom'
						selected={start_date}
						width={200}
						// style={{ width: 200 }}
						onChange={handle_date_change}
						startDate={start_date}
						endDate={end_date}
						selectsRange
						inline
						maxDate={max_date}
						// renderCustomHeader={({
						// 	date,
						// 	changeYear,
						// 	changeMonth,
						// 	decreaseMonth,
						// 	increaseMonth,
						// 	prevMonthButtonDisabled,
						// 	nextMonthButtonDisabled,
						// }) => (
						// 	<div
						// 		style={{
						// 			margin: 10,
						// 			display: 'flex',
						// 			justifyContent: 'center',
						// 			border: '1px solid red',
						// 			height: '100%',
						// 		}}>
						// 		<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
						// 			{'<'}
						// 		</button>
						// 		<select value={date.getYear()} onChange={({ target: { value } }) => changeYear(value)}>
						// 			{years.map((option) => (
						// 				<option key={option} value={option}>
						// 					{option}
						// 				</option>
						// 			))}
						// 		</select>

						// 		<select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
						// 			{months.map((option) => (
						// 				<option key={option} value={option}>
						// 					{option}
						// 				</option>
						// 			))}
						// 		</select>

						// 		<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
						// 			{'>'}
						// 		</button>
						// 	</div>
						// )}
					/>
				</div>
				<div className={classes.right_wrap}>
					<div>
						<Text semi className={classes.time_label}>
							Start Time ({moment(start_date).format('DD MMM ‘YY')})*
						</Text>
						<TimeInput value={start_time} handleChange={(value) => set_start_time(value)} />
						<Text semi className={classes.time_label} style={{ marginTop: 12 }}>
							{`End Time (${end_date ? moment(end_date).format('DD MMM ‘YY') : 'DD MMM ‘YY'})*`}
						</Text>
						<TimeInput value={end_time} handleChange={(value) => set_end_time(value)} />
					</div>

					<ContainedButton disabled={btn_disabled} style={{ borderRadius: 30 }} onClick={handle_range_change}>
						<Text semi style={{ fontSize: 12 }}>
							Done
						</Text>
					</ContainedButton>
				</div>
			</div>
		</div>
	);
};

export default DateTimeRangePicker;
