import { makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import DashboardContext from '../context';
import _ from 'lodash';
import moment from 'moment';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'resources/ImageLinks';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		right: 20,
		top: 20,
		zIndex: 1000,
	},
	alert_box_wrap: {
		width: 300,
		border: `1px solid ${theme.colors.red}`,
		backgroundColor: theme.colors.white,
		borderRadius: 4,
		padding: 12,
		marginTop: 10,
		cursor: 'pointer',
	},
}));

function AlertList() {
	const { interval_groups, selected_interval_index, set_selected_interval_index, set_center_position, set_zoom } = useContext(DashboardContext);
	const classes = useStyles();
	const [highlighted, set_highlighted] = useState(null);

	useEffect(() => {
		set_highlighted(null);
	}, [selected_interval_index]);

	return (
		<div className={classes.container}>
			{selected_interval_index != null && (
				<>
					<div className='d-flex ' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
						<Text bold style={{ color: theme.colors.primary, fontSize: 16 }}>
							Alert Detail
						</Text>
						<img
							src={ImageLinks.cross_circle_solid_purple}
							width={16}
							height={16}
							style={{ cursor: 'pointer' }}
							onClick={() => {
								set_selected_interval_index(null);
							}}
						/>
					</div>
					{_.map(interval_groups[selected_interval_index].value, (item, index) => {
						return (
							<div
								className={classes.alert_box_wrap}
								onClick={() => {
									if (!item.data?.location?.latitude || !item.data?.location?.longitude) return;
									set_center_position([item.data.location.latitude, item.data.location.longitude]);
									set_zoom(18);
									set_highlighted(index);
								}}
								style={{ backgroundColor: highlighted == index ? theme.colors.light_primary : theme.colors.white }}>
								<Text semi style={{ fontSize: 12, color: theme.colors.gray }}>{`${moment(item.timestamp).format('HH:mm')} | ${
									item.alias
								}`}</Text>
								<Text medium style={{ fontSize: 12, color: theme.colors.gray }}>
									{item.description}
								</Text>
							</div>
						);
					})}
				</>
			)}
		</div>
	);
}

export default AlertList;
